import {
  Box,
  Flex,
  Tabs,
  TabList,
  TabPanels,
  TabPanel,
  Text,
  Divider,
  Progress,
  Center,
  HStack,
} from '@chakra-ui/react';

import {
  AssetTypes,useAssetContext,
  BrandAssetDocument
} from 'src/db';
import { Path } from 'src/nav';

import { HeaderFooterPage } from './wrappers/HeaderFooterPage';
import { RequireBusinessWithSignup } from './wrappers/RequireBusiness'; 

import StyledTab from 'src/components/StyledTab';
import { BackButton, NextButton } from 'src/components/NavigationButtons';
import { AddBrandAssetButton } from 'src/components/assets/addAsset/AddBrandAsset';
import { VerticalAssetList } from './assets/VerticalAssetList';

const SIDE_PADDING = 4;

export function AssetsPage() {
  return (
    <HeaderFooterPage
      pageTitle='Assets'
      backTo={Path.brand}
    >
      <RequireBusinessWithSignup>
        <AssetsPageContent />
      </RequireBusinessWithSignup>
    </HeaderFooterPage>
  );
}

function AssetsPageContent() { 
  const previousPage = Path.logo;
  const nextPage = Path.name;

  const {
    collection: brandAssets,
    isLoading: logoAssetsAreLoading,
    error: collectionError,
  } = useAssetContext<BrandAssetDocument>(AssetTypes.brand);

  if (collectionError) {
    return <em>Could Not Find Assets</em>;
  } 

  if (logoAssetsAreLoading) {
    return (<>
      <Box py={8} px={SIDE_PADDING}>
        <Progress size='lg' isIndeterminate={true} />
      </Box>
    </>);
  }

  return (<>
    <Center>
      <AddBrandAssetButton
        variant='ghost'
        colorScheme='primary'
      >+&nbsp;Add Your Own
      </AddBrandAssetButton>
    </Center>

    <Flex
      align='center'
      direction='column'
      justify='start'
      px={SIDE_PADDING}
      mb={4}
    >
      <Divider borderColor='secondary.light' mb={8} />
      <Text textStyle='titleForSection'>Your Assets</Text>
    </Flex>
    <Tabs
      defaultIndex={0}
      variant='unstyled'
      align='center'
      isFitted={true}
    >
      <TabList>
        <StyledTab>{`Uploaded (${brandAssets.length})`}</StyledTab>
      </TabList>
      <TabPanels>
        <TabPanel p={2}>
          <VerticalAssetList
            assets={brandAssets}
          />
        </TabPanel>
      </TabPanels>
    </Tabs>
    <Box py="2">
      <NextButton redirectTo={{ to: nextPage }} />
    </Box>
  </>);
}
