import React, { useCallback, useState } from 'react';
import { deleteUser } from 'firebase/auth';
import {
  Button,
  ButtonProps,
  Flex,
  Input,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Text,
  VStack,
  useDisclosure,
  Box,
  Image,
} from '@chakra-ui/react';

import { useAuth, useUser } from 'src/auth';
import { Path, useNavigateWithParams } from 'src/nav';
import { Trash } from 'src/images';

export function DeleteUserButton({ children, ...buttonProps }: ButtonProps) {
  const navigate = useNavigateWithParams();
  const authErrorHandler = useAuth()[1];
  const user = useUser()!;
  const [isDeleting, setIsDeleting] = useState<boolean>(false); 
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [confirmationInput, setConfirmationInput] = useState<string>('');
  const hasCorrectConfirmation = confirmationInput.toUpperCase() === 'DELETE';

  const handleDeleteUser = useCallback(() => {
    if (!hasCorrectConfirmation || isDeleting) { return; }
    setIsDeleting(true);
    deleteUser(user).then(() => {
      navigate({to: Path.logout});
    }).catch(authErrorHandler).finally(() => {
      setIsDeleting(false);
    });
  }, [
    navigate, authErrorHandler, user, isDeleting, setIsDeleting, hasCorrectConfirmation
  ]);

  return (<>
    <Button
      variant='whiteGhost'
      color='red.500'
      {...buttonProps}
      onClick={() => { onOpen() }}
    >{children ||  <Box display="flex" alignItems="center" gap="8px">
      <Image src={Trash} alt="delete account" height="22px" ml="1" />
      <Text textStyle="body" color="error">Delete Account</Text>
    </Box>}</Button>

    <Modal
      isOpen={isOpen}
      onClose={onClose}
      isCentered={true} 
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader textStyle='titleForSection'>
          Delete Account
        </ModalHeader>
        <ModalBody>
          <VStack align='stretch'>
            <Text textStyle='bodyLarge' mb={4}>
              Are your sure your want to delete your account?
            </Text>
            <Text textStyle='body2'>
              This action cannot be undone. To confirm, type 'DELETE'.
            </Text>
            <Input
              variant='outline'
              value={confirmationInput}
              textAlign='center'
              width='120px' mx='auto'
              onChange={(e) => {
                setConfirmationInput(e.target.value);
              }}
            />
          </VStack>
        </ModalBody>
        <ModalFooter>
          <Flex width='100%' direction='row' align='center' wrap='wrap' justify='space-between'>
            <Button
              variant='outline'
              colorScheme='primary'
              onClick={onClose}
            >Nevermind</Button>
            <Button
              variant='fill'
              colorScheme='none' // TODO: Add system colorSchemes
              color='grayscale.white'
              bg='red.500'
              isDisabled={!hasCorrectConfirmation}
              isLoading={isDeleting}
              loadingText='Deleting...'
              onClick={() => {handleDeleteUser();}}
            >Delete Account</Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  </>);
}
