import React, { ReactNode, PropsWithChildren, useContext } from "react";

import { Box, BoxProps, Flex } from "@chakra-ui/react";

import { HeaderMenu } from "src/components";
import { Path } from "src/nav";

import { Footer } from "../layout/Footer";
import BottomNavigationBar from "./BottomNavigationBar/BottomNavigationBar";
import { UserContext } from "src/auth";
import DesktopHeaderMenu from "src/components/HeaderMenu/Desktop/DesktopHeaderMenu";
import { useScreenDetector } from "src/components/hooks/useScreenDetector";

interface HeaderFooterProps extends BoxProps {
  pageTitle?: string;
  // if missing, no back button.
  backTo?: Path;
  // Optional element in the top right corner
  topRightContent?: ReactNode;
  // Optional element in the top right corner
  banner?: ReactNode;
}

const sidePadding = 4;

export function HeaderFooterPage({
  pageTitle,
  backTo,
  banner = null,
  children,
}: PropsWithChildren<HeaderFooterProps>) {
  const user = useContext(UserContext);

  const isSignedIn = !!user && !user.isAnonymous;

  const { isMobile } = useScreenDetector();

  if (!isMobile && isSignedIn)
    return (
      <DesktopHeaderMenu backTo={backTo} pageTitle={pageTitle}>{children}</DesktopHeaderMenu>
    );

  return (
    <Flex
      direction="column"
      align="stretch"
      minHeight="100vh"
      maxWidth="600px"
      margin="0 auto"
      bg="grayscale.offwhite"
      boxShadow="section"
    >
      <HeaderMenu
        px={sidePadding}
        py={2}
        flex="0 0 fit-content"
        backTo={backTo}
        pageTitle={pageTitle}
      />
      <Box as="main" position="relative" flex="1 0 0" py="2">
        {banner}
        <Box px={sidePadding} width="100%">
          {children}
        </Box>
      </Box>
      {isSignedIn && !backTo && <BottomNavigationBar />}
      <Footer flex="0 0 fit-content" />
    </Flex>
  );
}
