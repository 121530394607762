import {
  Box,
  Icon,
  IconButton,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import { MdKeyboardArrowLeft } from "react-icons/md";
import { NotificationDocument } from "src/db/model/notification";
import { useCallback, useContext, useEffect, useState } from "react";
import { useApi } from "src/api";
import Notification from "./Notification";
import { Path } from "src/nav";
import { useNavigate } from "react-router-dom";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { FirestoreContext } from "src/db";

interface INotificationsModalProps {
  isOpen: boolean;
  notifications: NotificationDocument[] | null;
  handleClose: () => void;
}

type Submitting = {
  notificationId: string | null;
  loading: boolean;
};

const NotificationsModal = ({
  isOpen,
  notifications,
  handleClose,
}: INotificationsModalProps) => {
  const [isSubmitting, setIsSubmitting] = useState<Submitting>({
    notificationId: null,
    loading: false,
  });

  const [api, surfaceKnownErrors] = useApi();

  const navigate = useNavigate();

  const fs = useContext(FirestoreContext);

  const markAllAsARead = useCallback(async () => {
    if (!notifications) return

    const unreadNotifications = notifications.filter(n => !n.read)

    for (const notification of unreadNotifications) {
      const docRef = doc(fs, 'notifications', notification.id);
      const docSnap = await getDoc(docRef);
  
      if (docSnap.exists()) {
        updateDoc(docRef, { read: true });
      }
    }
  }, [fs, notifications])

  useEffect(() => {
    if(notifications) {
      markAllAsARead()
    }
  }, [markAllAsARead, notifications])

  const handleDecline = (notification: NotificationDocument) => {
    if (isSubmitting.loading) {
      return;
    }
    setIsSubmitting({ notificationId: notification.id, loading: true });
    api
      .declineMember(
        {
          businessId: notification.businessId,
          userId: notification.recipientId,
        },
        surfaceKnownErrors
      )
      .finally(() => {
        setIsSubmitting({ notificationId: null, loading: false });
      });
  };

  const handleAccept = (notification: NotificationDocument) => {
    return navigate({
      pathname: Path.createBusiness,
      search: `?joinBusinessId=${notification.businessId}`,
    });
  }

  return (
    <Modal isOpen={isOpen} onClose={handleClose} size="full">
      <ModalOverlay />
      <ModalContent maxH="75%" maxWidth={800}>
        <ModalHeader
          bg="grayscale.offwhite"
          borderColor="gray.lightest"
          borderBottomWidth="2px"
          borderTopWidth="2px"
          borderStyle="solid"
        >
          <Box display="flex" alignItems="center" justifyContent="center">
            <Box position="absolute" left="4">
              <IconButton
                icon={
                  <Icon
                    as={MdKeyboardArrowLeft}
                    color={"primary.veryDark"}
                    boxSize="32px"
                  />
                }
                aria-label="Menu"
                onClick={handleClose}
                bg={"transparent"}
              />
            </Box>
            <Box>
              <Text color="primary.veryDark">Notifications</Text>
            </Box>
          </Box>
        </ModalHeader>
        <ModalBody>
          {notifications &&
            notifications.map((notification: NotificationDocument) => {
              return (
                <Notification
                  key={notification.id}
                  loadingDecline={
                    isSubmitting.notificationId === notification.id &&
                    isSubmitting.loading
                  }
                  notification={notification}
                  handleDecline={handleDecline}
                  handleAccept={handleAccept}
                />
              );
            })}
          {!Boolean(notifications?.length) && (
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              pt="4"
            >
              <Text>No Notifications Yet</Text>
            </Box>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default NotificationsModal;
