import { Box, Flex, IconButton, Image, Text, VStack } from "@chakra-ui/react";
import { useNavigateWithParams } from "src/nav";
import { useLocation } from "react-router-dom";
import { menuItems } from "./items";
import { useEffect, useState } from "react";
import { useBusiness } from "src/db";
import { PermanentChat } from "src/components/PermanentChat";

const BottomNavigationBar = () => {
  const navigate = useNavigateWithParams();
  const location = useLocation();
  const business = useBusiness();

  const [navWidth, setNavWidth] = useState(0);

  useEffect(() => {
    const updateNavWidth = () => {
      const main: HTMLElement | null = document.querySelector("main");
      if (main) {
        setNavWidth(main.offsetWidth);
      }
    };
    updateNavWidth();
    window.addEventListener("resize", updateNavWidth);
    return () => {
      window.removeEventListener("resize", updateNavWidth);
    };
  }, []);

  const handleNavigationClick = (item: MenuItem) => {
    navigate({
      to: item.path,
      modifySearch: item.param ? { [item.param]: item.paramValue } : {},
    });
  };

  return (
    <Box
      position="fixed"
      bottom="0"
      width={`${navWidth}px`}
      bg="gray.lightest"
      boxShadow="0 -2px 5px rgba(0, 0, 0, 0.1)"
      height="76px"
    >
      <Flex
        justify="space-around"
        align="center"
        height="100%"
        position="relative"
      >
        {menuItems.map((item) => {
          const active = item.path === location.pathname;
          return item.id === "chat" ? (
            <PermanentChat
              width="auto"
              bottom="30px"
              margin="0"
              key={item.id}
            />
          ) : (
            <VStack key={item.id} spacing="1" align="center">
              <IconButton
                aria-label={item.ariaLabel}
                icon={
                  <Image
                    src={active ? item.activeIcon : item.icon}
                    alt={item.label}
                    height="28px"
                  />
                }
                variant="ghost"
                size="lg"
                onClick={() => handleNavigationClick(item)}
                isDisabled={item.requireBusiness && !business}
              />
              <Text
                textStyle="bodySmall"
                color={active ? undefined : "gray.medium"}
              >
                {item.label}
              </Text>
            </VStack>
          );
        })}
      </Flex>
    </Box>
  );
};

export default BottomNavigationBar;
