import {SyntheticEvent, FC, useCallback, useMemo, useState, useEffect } from 'react';
import { FirestoreDataConverter } from 'firebase/firestore';

import {
  Box,
  BoxProps,
  Button,
  ButtonProps,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Icon,
  Progress,
  Text,
  VStack,
  StackDivider,
  Tooltip,
} from '@chakra-ui/react';
import { MdAdd } from 'react-icons/md';
import { FaQuestionCircle } from "react-icons/fa";
import { IoSparklesSharp } from "react-icons/io5";

import { ApiMethods, useApi, useApiPending } from 'src/api';
import { AssetTypes, AssetDocument, useBusinessId, useIsBrandKitLocked, useAssetContext } from 'src/db';
import { Path, useNavigateWithParams } from 'src/nav';
import { UpdateTextAssetButtonProps } from 'src/components/assets/updateAsset/UpdateTextAsset';
import { OPTIONAL_ASSETS } from 'src/api/constants';
import { useMandatoryAssetsCompleted } from 'src/components/BrandKitProgress' 

/*
FOR Text-Based BrandKit Cards (Audiences, Names, Slogans, Values)...

- Every Brandkit card has 2 modes (that render different things), 
  plus a "loading" mode.

- If the maximum # of assets are selected (ie. selectedForBrandKit == true),
  use SELECTED Mode. Otherwise, use SUGGESTION Mode

- SELECTED Mode:
  - Show only the selected asset(s)
  - Include an "edit" button to revisit the detail page (if not brandkit is not locked)
  // TODO: POSSIBLE REWORK of the "Inline Add" depending on Figma Comments...
  - If MAX_ALLOWABLE_SELECTED > 1 (for the asset type), show a (small) button at the bottom that says "add"
    - Clicking add renders an input box and "close" button
    - Pressing the "close" button reverts back to the "add" button
    - Pressing enter submits the new text, then reverts back to the "add" button
    - If the input box is rendered while the max allowable number of assets are already selected,
      - The input box will be rendered with an error message that asks the user to remove one first
      - Pressing enter will do nothing
  - If MAX_SELETED for the asset type == 1, show a (small) button at the bottom that says "replace"
    - The "replace" button will unselect the current, and add a new one.
  // END POSSIBLE REWORK

- SUGGESTIONS Mode:
  - Show all assets that are LIKED or INITIAl (Sort the LIKED assets to the top)
  - (Note: It's possible to show 0 assets)
  - There will always be a big button at the bottom that says "Review"
  - Include n "suggest more" button on the card as well.
  - Suggested assets will have an 'X' to review negative.
  - Put the all the above assets into a scroll container,
    and set the max height (if using vertical scrolling), based on the max allowable selection
  - (All assets that are LIKED/INITIAL can be seen, but some may be below the fold)
  // TODO: POSSIBLE REWORK, might have inline add on suggested instead of SELECTED.

- LOADING Mode:
  - (Convinient state to make it easier to hide mode specific features while assets are still laoding)
  - Show no assets, only the title card and loading indicator
  - Show no actions specific to either SELECTED/SUGGESTIONS

For Non-text assets (Logos, Fonts, Colors)...
- Same criteria for SELECTED mode and SUGGESTION mode, 
- Use horizontal scrolling instead of vertical scrolling
- Same "Review" button in SUGGESTION Mode,
- But no "add"/"replace" buttons in SELECTED Mode (see note for the future below)
*/

declare global {
  interface Window {
    Appcues: any;
  }
}

type UsageMode = 'SELECTED'|'SUGGESTIONS'|'LOADING';

export interface AssetsProps<T extends AssetDocument> {
  assets: T[];
  UpdateAssetButton?: FC<UpdateTextAssetButtonProps>;
  // Deprecated. Prefer `useIsBrandkitLocked` hook.
  isLocked?: boolean;
}

interface BrandKitCardProps<T extends AssetDocument> extends BoxProps {
  // Title for the card (UI Friendly Name of the Asset)
  title?: string;
  
  // Human friendly asset name for buttons, e.g. "Add ${assetUiName}"
  assetUiName?: string

  // API Asset Type
  assetType: AssetTypes;

  // Path to send user to review the asset
  reviewPath: Path;

  firestoreConverter: FirestoreDataConverter<T>

  // Function to render a specific asset in SELECTED Mode
  SelectedAssets: FC<AssetsProps<T>>;

  // Function to render a specific asset in SUGGESTIONS Mode
  SuggestedAssets: FC<AssetsProps<T>>;

  // Number of assets to generate from "Suggest More" button
  // Default is 3
  numberToGenerate?: number;

  // Maximum number of assets selectable.
  // Default is 1
  selectionLimit?: number;

  // Error message to show if user cannot add another.
  selectionLimitErrorMessage?: string;

  // A Button to open an "Add your own" modal. (optional)
  AddAssetButton?: FC<ButtonProps>;

  UpdateAssetButton?: FC<UpdateTextAssetButtonProps>;

  // To show a progress bar (e.g. during API call) instead of actions
  // Default is false
  // TODO: Allow parent to Pass in a Skeleton Component instead of using Progress bar
  isInProgress?: boolean;

  // Mark asset as optional. Not to be required when locking the brand kit (optional)
  optional?: boolean;

  // Show/Hide Suggest More button
  showSuggestMore?: boolean
}

const xPadding = 6;
const yPadding = 6;

export function BrandKitCard<T extends AssetDocument>({
  title = '',
  assetUiName = '',
  assetType,
  firestoreConverter,
  reviewPath,
  SelectedAssets,
  SuggestedAssets,
  numberToGenerate = 3,
  selectionLimit = 1,
  selectionLimitErrorMessage = 'Limit reached. Remove an Asset before adding another',
  AddAssetButton,
  UpdateAssetButton,
  isInProgress = false,
  showSuggestMore = true,
  ...boxProps
}: BrandKitCardProps<T>) {

  const [api, surfaceKnownErrors] = useApi();
  const navigate = useNavigateWithParams();
  const businessId = useBusinessId()!;
  const isBrandKitLocked = useIsBrandKitLocked();
  const [isInProgressLocal, setIsInProgressLocal] = useState<boolean>(isInProgress);
  const optional = OPTIONAL_ASSETS.includes(assetType)
  const {
    collection: assets,
    isLoading: assetsAreLoading,
    error: collectionError,
    isComplete,
  } = useAssetContext<T>(assetType);

  console.log('assets', assets)

  const mandatoryAssetsCompleted = useMandatoryAssetsCompleted();

  const selectedAssets = (assets || []).filter((asset: AssetDocument<unknown>) => {
    return asset.selected;
  });

  const suggestedAssets = (assets || []).filter((asset: AssetDocument<unknown>) => {
    return !asset.selected && asset.userReview !== 'NEGATIVE';
  });

  const usageMode: UsageMode = assetsAreLoading ? 'LOADING' : 
    isComplete ? 'SELECTED' : 'SUGGESTIONS';

  useEffect(() => {
    // Count the type as completed if at least one selected.
    if(!!selectedAssets.length){
      window.Appcues.track(`asset-selection-${assetType}`);
      console.log(`Sending event: asset-selection-${assetType}`);
    }
  }, [selectedAssets, selectionLimit, assetType]);

  const goToReview = useCallback((e: SyntheticEvent) => {
    e.stopPropagation();
    if (isBrandKitLocked) { return; }
    navigate({to: reviewPath});
  }, [isBrandKitLocked, navigate, reviewPath]);

  const suggestMore = useCallback(() => {
    setIsInProgressLocal(true);
    return api.generateAssets({
      businessId, assetType, numberToGenerate
    }, surfaceKnownErrors).finally(() => {
      setIsInProgressLocal(false);
    });
  }, [
    api, surfaceKnownErrors,
    businessId, assetType, numberToGenerate,
  ]);

  const outlineStyles = useMemo(() => {
    return  !!selectedAssets.length ? {} : {
      outline: '2px solid',
      outlineColor: 'secondary.light',
      boxShadow: 'highlightCard',
    };
  }, [selectedAssets]);

  if (collectionError) {
    return (<Card {...boxProps}>
      <em>Could Not Find Assets</em>
    </Card>);
  } 
  
  const ButtonReviewRedirect = () => {
    return (<Button colorScheme='secondary' onClick={goToReview}>
      Review
    </Button>)
  }

  return (
    <Card px={xPadding} py={yPadding} onClick={goToReview} {...outlineStyles} {...boxProps}>
      <CardHeader
        display='flex'
        flexDirection='row'
        alignItems='center'
        justifyContent='space-between'
      >
        <Text as='h2' textStyle='titleForSection' mb={4}>
          {title}
        </Text>
        {!!optional && <Text textStyle='body2' align='left' width='100%' mb={4}>&nbsp;(Optional)</Text>}

        {!isBrandKitLocked && 
          <>
            {usageMode === 'SELECTED' &&
              <ButtonReviewRedirect />
            }
            {usageMode === 'SUGGESTIONS' &&
              <SuggestMoreButton handleSuggest={suggestMore} />
            }
          </>
        }
      </CardHeader>
      <CardBody mb={4}>
        <VStack divider={<StackDivider />}>
          {!!selectedAssets.length && <>
            {selectedAssets.length < selectionLimit &&  <Text textStyle='body2' align='left' width='100%'>
              {`You can select up to ${selectionLimit}`}
            </Text>}
            <SelectedAssets assets={selectedAssets} UpdateAssetButton={UpdateAssetButton} isLocked={isBrandKitLocked}/>
          </>}
          
          {usageMode === 'SUGGESTIONS' && !!suggestedAssets.length && !mandatoryAssetsCompleted &&
            <SuggestedAssets assets={suggestedAssets} UpdateAssetButton={UpdateAssetButton} isLocked={isBrandKitLocked}/>
          }
          {usageMode === 'LOADING' &&
            <Progress
              size='lg'
              width='100%'
              isIndeterminate={true}
            />
          }
        </VStack>
        
      </CardBody>
      {usageMode === 'SUGGESTIONS' && !mandatoryAssetsCompleted &&
      <CardFooter
        display='flex'
        flexDirection='row'
        alignItems='center'
        justifyContent='center'
      >
        {isInProgressLocal ? 
          <Progress
            size='sm'
            width='100%'
            isIndeterminate={true} 
            mb={2}
          /> :
          <Button
            colorScheme='primary'
            variant='fill'
            minWidth='180px'
            onClick={goToReview}
          >
            Review
          </Button>
        }
      </CardFooter>}
      {!!AddAssetButton && !isComplete  && !mandatoryAssetsCompleted &&
        <AddAssetButton 
          aria-label={`Add ${assetUiName}`}
          variant='outlineFilled'
          colorScheme='primary'
          boxSize='64px'
          position='absolute'
          left={xPadding}
          bottom='-32px'
        >
          <Icon as={MdAdd} />
      </AddAssetButton>
      }
    </Card>
  );
}

interface SuggestMoreButtonProps {
  handleSuggest: () => Promise<any>;
}
function SuggestMoreButton({ handleSuggest }: SuggestMoreButtonProps) {
  const isGeneratingAny = useApiPending(ApiMethods.GENERATE_ASSETS); 
  const [isGeneratingLocal, setIsGeneratingLocal] = useState<boolean>(false);

  const onSuggestionsClick = useCallback((e: SyntheticEvent) => {
    e.stopPropagation();
    setIsGeneratingLocal(true);
    handleSuggest().finally(() => {
      setIsGeneratingLocal(false);
    });
  }, [handleSuggest]);

  if (isGeneratingAny && !isGeneratingLocal) {
    return (
      <Tooltip 
        label='Generating another asset'
        hasArrow={true}
        placement='top'
      >
        <Box tabIndex={0}>
          <Button
            colorScheme='secondary'
            isLoading={true} 
            spinnerPlacement='start'
            loadingText={<><span>Suggest More</span>&nbsp;<Icon as={FaQuestionCircle} /></>}
          />
        </Box>
      </Tooltip>
    );
  } else if (isGeneratingLocal) {
    return (
      <Tooltip 
        label='Still generating asset'
        hasArrow={true}
        placement='top'
      >
        <Box tabIndex={0}>
          <Button
            colorScheme='secondary'
            isLoading={true} 
            spinnerPlacement='start'
            loadingText={<><span>Suggest More</span>&nbsp;<Icon as={FaQuestionCircle} /></>}
          />
        </Box>
      </Tooltip>
    );
  } else {
    return (
      <Button
        colorScheme='secondary'
        onClick={onSuggestionsClick}
      >
        <Icon as={IoSparklesSharp} aria-hidden={true}/>&nbsp;<span>Suggest More</span>
      </Button>
    );
  }
}

// Useful to export for parody cards.
BrandKitCard.xPadding = xPadding;
BrandKitCard.yPadding = yPadding;
