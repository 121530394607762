import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  VStack,
  Tabs,
  TabList,
  HStack,
  Icon,
  Tab,
  TabPanels,
  Text,
  TabPanel,
  StackDivider,
  Flex,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverHeader,
  Button,
  useToast,
  IconButton,
  Input,
  Textarea,
  TextProps,
  Image,
  Box,
} from "@chakra-ui/react";

import { Path, useNavigateWithParams } from "src/nav";

import { HeaderFooterPage } from "./wrappers/HeaderFooterPage";
import { RequireProfile } from "./wrappers/RequireProfile";
import { stripeConfig } from "src/firebaseAndBackendConfig";
import {
  MdCheck,
  MdClose,
  MdEdit,
  MdPayment,
  MdPerson,
  MdVerified,
  MdWarning,
} from "react-icons/md";
import { useAuth, useUser } from "src/auth";
import {
  DeleteUserButton,
  GoogleAuthButton,
  ResetPasswordButton,
} from "src/components/auth";
import {
  ChangeEvent,
  ReactNode,
  useCallback,
  useMemo,
  useRef,
  useState,
} from "react";
import { GoogleAuthProvider, sendEmailVerification } from "firebase/auth";
import { FaGoogle } from "react-icons/fa6";
import { useProfileId, useProfileInfo } from "src/db/hooks";
import { useApi } from "src/api/hooks";
import { LogOut } from "src/images";

export function AccountPage() {
  return (
    <HeaderFooterPage pageTitle="Account" backTo={Path.home}>
      <RequireProfile excludeAnonymous={true} OrElse={<NoAccount />}>
        <AccountPageContent />
      </RequireProfile>
    </HeaderFooterPage>
  );
}

function NoAccount() {
  return (
    <Alert status="error">
      <AlertIcon />
      <AlertTitle>Account Not Found</AlertTitle>
      <AlertDescription>
        No user is logged in, or no profile was created
      </AlertDescription>
    </Alert>
  );
}

function VerifyableEmail() {
  const errorHandler = useAuth()[1];
  const toast = useToast();
  const user = useUser(true)!;
  const email = user.email || "(anonymous)";
  const isVerified = user.emailVerified;
  const [isSendingVerification, setIsSendingVerification] =
    useState<boolean>(false);

  const sendVerification = () => {
    if (!user || isSendingVerification) {
      return false;
    }
    setIsSendingVerification(true);
    sendEmailVerification(user)
      .then(() => {
        toast({
          status: "info",
          description: (
            <p>
              Sent verification email to:&nbsp;<em>{email}</em>
            </p>
          ),
        });
      })
      .catch(errorHandler)
      .finally(() => {
        setIsSendingVerification(false);
      });
  };

  return (
    <Flex direction="row" align="stretch">
      <VStack flex="1 0 0" align="start">
        <Text as="span" textStyle="titleForWidet">
          Email
        </Text>
        <Text as="span" textStyle="body">
          <span>{email}&nbsp;</span>
          {isVerified ? (
            <Popover size="sm">
              <PopoverTrigger>
                <span>
                  <Icon
                    as={MdVerified}
                    color="green.500"
                    tabIndex={0}
                    cursor="pointer"
                  />
                </span>
              </PopoverTrigger>
              <PopoverContent>
                <PopoverArrow />
                <PopoverHeader>Verified Email Address</PopoverHeader>
              </PopoverContent>
            </Popover>
          ) : (
            <span>
              <Button
                isLoading={isSendingVerification}
                mx="auto"
                onClick={() => {
                  sendVerification();
                }}
              >
                <Icon as={MdWarning} color="orange.500" />
                Verify Email
              </Button>
            </span>
          )}
        </Text>
      </VStack>
      {/* TODO: Change email Button */}
    </Flex>
  );
}

function ResettablePassword() {
  const email = useUser(true)!.email || "";

  return (
    <Flex direction="row" align="stretch">
      <VStack flex="1 0 0" align="start">
        <Text as="span" textStyle="titleForWidet">
          Password
        </Text>
        <ResetPasswordButton
          email={email}
          variant="ghost"
          m={0}
          p={0}
          height="fit-content"
        />
      </VStack>
    </Flex>
  );
}

const tabStyles = {
  border: "none",
  borderBottom: "2px solid",
  borderColor: "primary.light",
  color: "primary.dark",
  textStyle: "titleForGroup",
  fontWeight: "normal",
};
const selectedTabStyles = {
  color: "primary.veryDark",
  borderColor: "secondary.dark",
  fontWeight: "bold",
};

const openCustomerPortal = (email: string | undefined) => {
  let redirectLink = stripeConfig.customerPortalBaseLink;
  if (email) {
    redirectLink = redirectLink + `?prefilled_email=${email}`;
  }

  window.open(redirectLink, "_blank");
};

// TODO: Move this into shared components. Social Media Post has a similiar function.
type InputOrTextarea = HTMLInputElement | HTMLTextAreaElement;

interface EditableFieldProps {
  label: string;
  multiline: boolean;
  startingValue: string;
  placeholder?: ReactNode;
  handleSave: (input: string) => Promise<any>;
}
function EditableField({
  label,
  multiline,
  startingValue,
  placeholder = "",
  handleSave,
}: EditableFieldProps) {
  const editButtonRef = useRef<HTMLButtonElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const [currentValue, setCurrentValue] = useState<string>(startingValue);
  const canSubmit = currentValue !== startingValue;
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const enterEditMode = useCallback(() => {
    setIsEditMode(true);
    if (multiline) {
      setTimeout(() => {
        textareaRef.current!.focus();
      }, 0);
    } else {
      setTimeout(() => {
        inputRef.current!.focus();
      }, 0);
    }
  }, [multiline, inputRef, textareaRef, setIsEditMode]);

  const exitEditMode = useCallback(() => {
    setIsEditMode(false);
    setTimeout(() => {
      editButtonRef.current?.focus();
    }, 0);
  }, [editButtonRef, setIsEditMode]);

  const handleInputChange = useCallback(
    (e: ChangeEvent<InputOrTextarea>) => {
      setCurrentValue(e.target.value);
    },
    [setCurrentValue]
  );

  const saveValue = useCallback(() => {
    if (!canSubmit) {
      return;
    }
    setIsSaving(true);
    handleSave(currentValue).finally(() => {
      setIsSaving(false);
      exitEditMode();
    });
  }, [handleSave, currentValue, canSubmit, setIsSaving, exitEditMode]);

  const textStyleProps: TextProps = useMemo(() => {
    return {
      align: "left",
      textStyle: "body",
      width: "100%",
      whiteSpace: "pre-line",
    };
  }, []);

  return (
    <Flex direction="row" align="stretch">
      <VStack flex="1 0 0" align="start">
        <Text as="span" textStyle="titleForWidet">
          {label}
        </Text>
        {isEditMode ? (
          multiline ? (
            <Textarea
              ref={textareaRef}
              value={currentValue}
              onChange={handleInputChange}
            />
          ) : (
            <Input
              ref={inputRef}
              value={currentValue}
              onChange={handleInputChange}
            />
          )
        ) : (
          <Text
            {...textStyleProps}
            fontStyle={!!currentValue ? "inherit" : "italic"}
          >
            {currentValue || placeholder}
          </Text>
        )}
      </VStack>
      <HStack flex="0 0 fit-content" gap={2} align="start">
        {isEditMode ? (
          <>
            <IconButton
              variant="ghost"
              aria-label="Submit"
              fontSize="24px"
              icon={<MdCheck />}
              isDisabled={!canSubmit}
              isLoading={isSaving}
              onClick={() => {
                saveValue();
              }}
            />
            <IconButton
              variant="ghost"
              aria-label="Cancel"
              fontSize="24px"
              icon={<MdClose />}
              onClick={() => {
                exitEditMode();
              }}
            />
          </>
        ) : (
          <IconButton
            variant="ghost"
            aria-label="Edit"
            fontSize="24px"
            icon={<MdEdit />}
            onClick={() => {
              enterEditMode();
            }}
          />
        )}
      </HStack>
    </Flex>
  );
}

function EditableName() {
  const [api, surfaceKnownErrors] = useApi();
  const profileId = useProfileId()!;
  const profileInfo = useProfileInfo()!;
  const handleSave = (newValue: string) => {
    return api.updateProfile(
      {
        profileId,
        toUpdateProfileInfo: {
          name: newValue,
        },
      },
      surfaceKnownErrors
    );
  };

  return (
    <EditableField
      label="Name"
      startingValue={profileInfo.name || ""}
      multiline={false}
      handleSave={handleSave}
    />
  );
}

function EditablePhone() {
  const [api, surfaceKnownErrors] = useApi();
  const profileId = useProfileId()!;
  const profileInfo = useProfileInfo()!;
  const handleSave = (newValue: string) => {
    return api.updateProfile(
      {
        profileId,
        toUpdateProfileInfo: {
          phone: newValue,
        },
      },
      surfaceKnownErrors
    );
  };

  return (
    <EditableField
      label="Phone"
      startingValue={profileInfo.phone || ""}
      placeholder="Ex: (123) 456-7890"
      multiline={false}
      handleSave={handleSave}
    />
  );
}

function EditableAddress() {
  const [api, surfaceKnownErrors] = useApi();
  const profileId = useProfileId()!;
  const profileInfo = useProfileInfo()!;
  const handleSave = (newValue: string) => {
    return api.updateProfile(
      {
        profileId,
        toUpdateProfileInfo: {
          address: newValue,
        },
      },
      surfaceKnownErrors
    );
  };

  return (
    <EditableField
      label="Address"
      startingValue={profileInfo.address || ""}
      placeholder={
        <>
          <span>Example:</span>
          <br />
          <span>123 Street</span>
          <br />
          <span>City, State</span>
          <br />
          <span>12345</span>
          <br />
        </>
      }
      multiline={true}
      handleSave={handleSave}
    />
  );
}

function LinkableGoogle() {
  const user = useUser()!;
  const googleUserInfo = user.providerData.find((userInfo) => {
    return userInfo.providerId === GoogleAuthProvider.PROVIDER_ID;
  });

  return (
    <VStack flex="1 0 0" align="start">
      <HStack align="center">
        <Icon as={FaGoogle} aria-label="Google Logo" />
        <Text textStyle="body">Google</Text>
        {!!googleUserInfo ? (
          <>
            <Icon as={MdVerified} color="green.500" aria-label="linked" />
            <Text textStyle="body2">
              (Connected to <em>{googleUserInfo?.email}</em>)
            </Text>
          </>
        ) : (
          <Text textStyle="body2">(Not connected)</Text>
        )}
      </HStack>
      {!googleUserInfo && <GoogleAuthButton />}
    </VStack>
  );
}

function AccountPageContent() {
  const profileInfo = useProfileInfo()!;
  const navigate = useNavigateWithParams();

  return (
    <VStack width="100%" align="stretch" spacing={6} mb={6}>
      <Tabs variant="enclosed" align="center" isFitted={true}>
        <TabList>
          <Tab {...tabStyles} _selected={selectedTabStyles}>
            <HStack>
              <Icon as={MdPerson} />
              <span>Account Info</span>
            </HStack>
          </Tab>
          <Tab {...tabStyles} _selected={selectedTabStyles}>
            <HStack>
              <Icon as={MdPayment} />
              <span>Payment</span>
            </HStack>
          </Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <VStack align="stretch" spacing="16px">
              <Text textStyle="titleForGroup">Account Info</Text>
              <VStack
                align="stretch"
                divider={<StackDivider borderColor="grayscale.medium" />}
              >
                <VerifyableEmail />
                <ResettablePassword />
              </VStack>
              <Text textStyle="titleForGroup">Contact Info</Text>
              <VStack
                align="stretch"
                divider={<StackDivider borderColor="grayscale.medium" />}
              >
                <EditableName key="name" />
                <EditablePhone key="phone" />
                <EditableAddress key="address" />
              </VStack>
              <Text textStyle="titleForGroup">Linked Accounts</Text>
              <VStack
                align="stretch"
                divider={<StackDivider borderColor="grayscale.medium" />}
                mb="32px"
              >
                <LinkableGoogle key="google" />
              </VStack>
              <DeleteUserButton width="100%" mx="auto" />
              <Button variant="whiteGhost" onClick={() => navigate({ to: Path.logout })}>
                <Box display="flex" alignItems="center" gap="8px">
                  <Image src={LogOut} alt="log out" height="30px" />
                  <Text textStyle="body" color="primary.veryDark">Log out</Text>
                </Box>
              </Button>
            </VStack>
          </TabPanel>
          <TabPanel>
            <Button
              variant="outline"
              colorScheme="primary"
              onClick={() => openCustomerPortal(profileInfo.email)}
            >
              Manage Payment
            </Button>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </VStack>
  );
}
