import { BoxProps, Card, Text, Collapse, Center, Progress, Box, ButtonProps, Button, Flex, FormControl, Icon, IconButton, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Spacer, Textarea, Tooltip, useDisclosure, ModalFooter, VStack, Input } from '@chakra-ui/react';
import { SyntheticEvent, useCallback, useEffect, useRef, useState } from 'react';
import { IoIosArrowDown, IoIosArrowUp, IoIosCloseCircleOutline } from 'react-icons/io';
import { TbEditCircle } from 'react-icons/tb';
import Markdown from 'react-markdown';
import { useApi } from 'src/api';
import { DeleteReportRequest } from 'src/api/messages/DeleteReportContent';
import { UpdateReportRequest } from 'src/api/messages/UpdateReportContent';
import { useBusinessId } from 'src/db';
import { ReportDocument, ReportStatus, getSummary, getTitle } from 'src/db/model/report';

interface ReportPreviewProps extends BoxProps {
  report: ReportDocument;
  isOpen?: boolean;
}

export function ReportPreview({ report, isOpen, ...boxProps }: ReportPreviewProps) {
  const [isCollapsed, setIsCollapsed] = useState(isOpen);
  useEffect(() => {
    setIsCollapsed(!isOpen);
  }, [isOpen]);
  return (
    <Card onClick={() => setIsCollapsed(!isCollapsed)} size='md' width='100%' {...boxProps}>
      <Flex justify="space-between" align="center" m={0}>
        <Text textStyle="caption">
          {report.createdAt.toLocaleDateString()}
        </Text>
        { report.status !== ReportStatus.CREATING && <DeleteReportButton id={report.id} status={report.status} /> }
      </Flex>
      <Center><Text fontWeight="bold" fontSize="lg" color={report.status === ReportStatus.ERROR ? "red" : "inherit"}>
        {getTitle(report)}
      </Text>
      </Center>
      {report.status === ReportStatus.READY ? <>
        <Collapse in={!isCollapsed} animateOpacity>
          <Box padding='20px'>
            <UpdateReportButton
              id={report.id}
              content={report.content}
            >
            </UpdateReportButton>
            <Markdown className={"markdown"}>{report.content}</Markdown>
          </Box>
          <Box padding='20px'>
            <Markdown className={"markdown"}>{report.references}</Markdown>
          </Box>
        </Collapse>
        <IconButton
          icon={isCollapsed ? <IoIosArrowDown /> : <IoIosArrowUp />}
          aria-label="Toggle Collapse"
        />
      </> : <><Center><Text mt={2} fontStyle="italic">{getSummary(report)}</Text></Center><Progress isIndeterminate={report.status === ReportStatus.CREATING} /></>}
    </Card>)
}

export interface UpdateReportButtonProp
  extends ButtonProps {
  id: string;
  content: string;
}

function UpdateReportButton({
  id,
  content,
  ...buttonProps
}: UpdateReportButtonProp) {
  const inputRef = useRef<HTMLTextAreaElement>(null);
  const businessId = useBusinessId()!;
  const [api, surfaceKnownErrors] = useApi();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [textValue, setTextValue] = useState<string>(content);

  const handleSubmit = useCallback(
    (text: string) => {
      if (isSubmitting) {
        return;
      }
      if (text === content || !text) {
        onClose();
        return;
      }
      setIsSubmitting(true);
      const updateRequestData: UpdateReportRequest = {
        businessId: businessId,
        reportId: id,
        reportContent: text,
      };
      api
        .updateReport(updateRequestData, surfaceKnownErrors)
        .then(() => {
          onClose();
        })
        .finally(() => {
          setIsSubmitting(false);
        });
    },
    [isSubmitting, id, content, businessId, api, surfaceKnownErrors, onClose]
  );

  return <>
    <Tooltip label="Edit" placement="top">
      <IconButton
        aria-label="Edit Report Content"
        color="secondary.dark"
        icon={<Icon as={TbEditCircle} boxSize="24px" />}
        height="fit-content"
        {...buttonProps}
        onClick={(e: SyntheticEvent) => {
          e.stopPropagation();
          onOpen();
        }}
      />
    </Tooltip>

    <Modal
      isOpen={isOpen}
      onClose={onClose}
      isCentered={true}
      initialFocusRef={inputRef}
    >
      <ModalOverlay />
      <ModalContent m={4}>
        <ModalHeader>
          <Center>
            <Text textStyle="bodyLarge">{`Update Report Content`}</Text>
          </Center>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <form
            onSubmit={(e) => {
              // Prevent page refresh
              e.preventDefault();
              if (!!textValue) {
                handleSubmit(textValue);
              }
            }}
          >
            <Flex
              direction="row"
              align="center"
              justify="space-between"
              wrap="wrap"
            >
              <FormControl>
                <Textarea
                  ref={inputRef}
                  placeholder="Type here"
                  variant="filled"
                  value={textValue}
                  minHeight='300px'
                  onChange={(e) => {
                    setTextValue(e.target.value);
                  }}
                  isDisabled={isSubmitting}
                />
              </FormControl>
              <Spacer flex="0 0 8px" />
              <Button
                type="submit"
                variant="fill"
                colorScheme="primary"
                size="sm"
                flex="0 0 fit-content"
                isDisabled={!textValue}
                isLoading={isSubmitting}
              >
                Save
              </Button>
            </Flex>
          </form>
        </ModalBody>
      </ModalContent>
    </Modal>
  </>
}

export interface DeleteReportButtonProp
  extends ButtonProps {
  id: string;
  status: ReportStatus
}

function DeleteReportButton({
  id,
  status,
  ...buttonProps
}: DeleteReportButtonProp) {
  const businessId = useBusinessId()!;
  const [api, surfaceKnownErrors] = useApi();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [confirmationInput, setConfirmationInput] = useState<string>('');
  const hasCorrectConfirmation = confirmationInput.toUpperCase() === 'DELETE';
  const validReport = status === ReportStatus.READY;

  const handleDeleteReport = useCallback(() => {
    if (validReport && (!hasCorrectConfirmation || isDeleting)) { return; }
    setIsDeleting(true);
    const deleteRequestData: DeleteReportRequest = {
      businessId: businessId,
      reportId: id,
    };
    api
      .deleteReport(deleteRequestData, surfaceKnownErrors)
      .then(() => {
        onClose();
      })
      .finally(() => {
        setIsDeleting(false);
      });
  }, [
    validReport, api, businessId, id, onClose, surfaceKnownErrors, isDeleting, setIsDeleting, hasCorrectConfirmation
  ]);

  return <>
    <Tooltip label="Delete" placement="top">
      <IconButton
        aria-label='Remove'
        color='system.error.dark'
        icon={<Icon as={IoIosCloseCircleOutline} p={0} boxSize='24px' />}
        height='fit-content'
        {...buttonProps}
        onClick={(e: SyntheticEvent) => {
          e.stopPropagation();
          if (validReport) {
            onOpen();
          } else {
            handleDeleteReport();
          }
        }}
      />
    </Tooltip>

    <Modal
      isOpen={isOpen}
      onClose={onClose}
      isCentered={true}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader textStyle='titleForSection'>
          <Center>Delete Report</Center>
        </ModalHeader>
        <ModalBody>
          <VStack align='stretch'>
            <Text textStyle='bodyLarge' mb={4}>
              Are your sure your want to delete this report?
            </Text>
            <Text textStyle='body2'>
              This action cannot be undone. To confirm, type 'DELETE'.
            </Text>
            <Input
              variant='outline'
              value={confirmationInput}
              textAlign='center'
              width='120px' mx='auto'
              onChange={(e) => {
                setConfirmationInput(e.target.value);
              }}
            />
          </VStack>
        </ModalBody>
        <ModalFooter>
          <Flex width='100%' direction='row' align='center' wrap='wrap' justify='space-between'>
            <Button
              variant='outline'
              colorScheme='primary'
              onClick={onClose}
            >Cancel</Button>
            <Button
              variant='fill'
              colorScheme='none' // TODO: Add system colorSchemes
              color='grayscale.white'
              bg='red.500'
              isDisabled={!hasCorrectConfirmation}
              isLoading={isDeleting}
              loadingText='Deleting...'
              onClick={() => { handleDeleteReport(); }}
            >Delete Report</Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  </>
}