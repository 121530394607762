import home from "./home.svg";
import homeActive from "./home-active.svg";
import homeWhite from "./home-white.svg";
import create from "./create.svg";
import createActive from "./create-active.svg";
import createWhite from "./create-white.svg";
import brand from "./brand.svg";
import brandActive from "./brand-active.svg";
import brandWhite from "./brand-white.svg";
import settings from "./settings.svg";
import settingsActive from "./settings-active.svg";
import settingsWhite from "./settings-white.svg";
import { Path, SearchParam } from "src/nav";
import { avatar } from "src/images";

export const settingsMenuItem = {
  id: "settings",
  label: "Settings",
  icon: settings,
  activeIcon: settingsActive,
  whiteIcon: settingsWhite,
  ariaLabel: "Settings",
  path: Path.settings,
  onlyMobile: true,
  requireBusiness: true,
};

export const menuItems: MenuItem[] = [
  {
    id: "home",
    label: "Home",
    icon: home,
    activeIcon: homeActive,
    whiteIcon: homeWhite,
    ariaLabel: "Home",
    path: Path.home,
    requireBusiness: true,
  },
  {
    id: "create",
    label: "Create",
    icon: create,
    activeIcon: createActive,
    whiteIcon: createWhite,
    ariaLabel: "Create",
    path: Path.create,
    paramValue: 'true',
    requireBusiness: true,
  },
  {
    id: "chat",
    label: "Chat",
    icon: avatar,
    activeIcon: avatar,
    ariaLabel: "Chat",
    path: Path.home,
    onlyMobile: true,
    requireBusiness: true,
  },
  {
    id: "brand",
    label: "Brand",
    icon: brand,
    activeIcon: brandActive,
    whiteIcon: brandWhite,
    ariaLabel: "Brand",
    path: Path.brand,
    requireBusiness: true,
  },
  settingsMenuItem,
];
