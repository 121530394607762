import React, { RefObject, MutableRefObject, useCallback, useState, useEffect } from 'react';
import {
  BoxProps,
  Flex,
  Modal,
  ModalOverlay,
} from '@chakra-ui/react'

import { useLocalConversation } from 'src/components/chat';
import { Path, useNavigateWithParams } from 'src/nav';

import { ConversationContext, ConversationModalContent, MessageBox, ModalAction } from 'src/components/chat';

import { useCreateBusinessPrefill } from './hooks';

enum ConvoPhase {
  NEW = 'NEW',
  PROMPT_DESCRIPTION = 'P_DESCRIPTION',
  AWAIT_DESCRIPTION = 'A_DESCRIPTION',
  PROMPT_NAME = 'P_NAME',
  AWAIT_NAME = 'A_NAME',
  PROMPT_EMAIL = 'P_EMAIL',
  PROMPT_EMAIL_INVALID = 'P_EMAIL_INVALID',
  AWAIT_EMAIL = 'A_EMAIL',
  DONE = 'DONE',
}
const standardMessageDelay = 400;
const NOT_SURE = 'not sure';
const sureName = (name: string): string|undefined => {
  return name.toLowerCase() !== NOT_SURE ? name : undefined;
};
const SKIP_EMAIL = 'No thanks. I will wait instead';
const isSkipEmail = (email: string): boolean => {
  return email === SKIP_EMAIL;
};

interface CreateBusinessConversationProps extends BoxProps {
  toggleModalRef: MutableRefObject<ModalAction|null>,
  finalFocusRef?: RefObject<HTMLElement>,
}

export function CreateBusinessConversation({
  toggleModalRef, finalFocusRef, ...boxProps
}: CreateBusinessConversationProps) {
  const navigate = useNavigateWithParams();
  const [convoPhase, setConvoPhase] = useState<ConvoPhase>(ConvoPhase.NEW);
  const [isAiThinking, setIsAiThinking] = useState<boolean>(false);
  const [prefill, setPrefill] = useCreateBusinessPrefill();
  const [hasModalOpened, setHasModalOpened] = useState<boolean>(false);
  const conversation = useLocalConversation([{  
    source: 'ai',
    body: 'Hello. I\'m Agent Brandi and I\'m excited to help you.'
  }]);
  const {
    messages, pending,
    addMessage, sendMessage, 
    isModalOpen, toggleModal,
  } = conversation;
  toggleModalRef.current = toggleModal;
  const lastMessage = messages[messages.length - 1]!;
  const lastHumanAnswer: string|null = lastMessage.source === 'human' ? lastMessage.body : null;

  // Before adding a prompt, include a delay.
  const goToPromptPhase = useCallback((phase: ConvoPhase) => {
    setIsAiThinking(true);
    return setTimeoutWithCleanup(() => {
      setIsAiThinking(false);
      setConvoPhase(phase);
    }, standardMessageDelay);
  }, []);

  const navigateToCreate = useCallback(() => {
    navigate({to: Path.createBusiness});
  }, [navigate]);

  useEffect(() => {
    if (isModalOpen) {
      setHasModalOpened(true);
    }
  }, [isModalOpen]);

  // Instantly move pending messages into the convo (because no backend to handle them)
  useEffect(() => {
    if (!!pending.length) {
      addMessage({
        source: 'human',
        body: pending[0], 
      });
    }
  }, [pending, addMessage]);

  // Main Conversation Logic
  useEffect(() => {
    switch (convoPhase) {
      // Start conversation if conditions met.
      case ConvoPhase.NEW: {
        if (hasModalOpened || !!prefill?.description) {
          return goToPromptPhase(ConvoPhase.PROMPT_DESCRIPTION);
        }
        return;
      }

      // ADD PROMPT PHASES
      case ConvoPhase.PROMPT_DESCRIPTION: {
        addMessage({
          source: 'ai', 
          body: 'To begin, describe your business'
        });
        if (!!prefill?.description) {
          sendMessage({
            source: 'human', 
            body: prefill.description!
          });
        }
        setConvoPhase(ConvoPhase.AWAIT_DESCRIPTION);
        return;
      }
      case ConvoPhase.PROMPT_NAME: {
        addMessage({
          source: 'ai',
          body: 'Great! Type your business name if you have one, or "not sure" if you want suggestions'
        });
        if (!!prefill?.name) {
          sendMessage({
            source: 'human', 
            body: prefill.name!
          });
        } else {
          addMessage({
            source: 'ai',
            body: NOT_SURE,
            specialUiType: 'aiSuggestion',
          });
        }
        setConvoPhase(ConvoPhase.AWAIT_NAME);
        return;
      }
      case ConvoPhase.PROMPT_EMAIL: {
        addMessage({
          source: 'ai',
          body: 'It will take several minutes to create your brand. '
            + 'What is an email address to contact you at when it is finished?',
        });
        if (!!prefill?.email) {
          sendMessage({
            source: 'human', 
            body: prefill.email!
          });
        } else {
          addMessage({
            source: 'ai',
            body: SKIP_EMAIL,
            specialUiType: 'aiSuggestion',
          });
        }
        setConvoPhase(ConvoPhase.AWAIT_EMAIL);
        return;
      }
      case ConvoPhase.PROMPT_EMAIL_INVALID: {
        addMessage({
          source: 'ai',
          body: 'Please enter a valid email address.',
        });
        setConvoPhase(ConvoPhase.AWAIT_EMAIL);
        return;
      }

      // HANDLE ANSWER PHASES (Ensure last message was AI)
      case ConvoPhase.AWAIT_DESCRIPTION: {
        if (!!lastHumanAnswer) {
          setPrefill((previous) => {
            return {...previous, description: lastHumanAnswer };
          });
          return goToPromptPhase(ConvoPhase.PROMPT_NAME); 
        } else {
          return setTimeoutWithCleanup(() => {
            addMessage({
              source: 'ai', 
              body: 'Here\'s an example:\n'
              +'"I make and sell scarves for dogs from vintage screen printed fabrics"',
            });
          }, 5000); 
        }
      }
      case ConvoPhase.AWAIT_NAME: {
        if (!lastHumanAnswer) { return; } 
        setPrefill((previous) => {
          return {...previous, name: sureName(lastHumanAnswer) };
        });
        return goToPromptPhase(ConvoPhase.PROMPT_EMAIL); 
      }
      case ConvoPhase.AWAIT_EMAIL: {
        if (!lastHumanAnswer) { return; } 
        if (isSkipEmail(lastHumanAnswer)) {
          setPrefill((previous) => {
            return {...previous, email: undefined };
          });
          return goToPromptPhase(ConvoPhase.DONE); 
        }
        if (isValidEmail(lastHumanAnswer)) {
          setPrefill((previous) => {
            return {...previous, email: lastHumanAnswer };
          });
          return goToPromptPhase(ConvoPhase.DONE); 
        } else {
          setPrefill((previous) => {
            return {...previous, email: undefined };
          });
          return goToPromptPhase(ConvoPhase.PROMPT_EMAIL_INVALID); 
        }
      }

      // Navigate to create business when done
      case ConvoPhase.DONE: {
        if (!!lastHumanAnswer) {
          addMessage({
            source: 'ai',
            body: 'Creating your brand...',
          });
        } 
        return setTimeoutWithCleanup(navigateToCreate, standardMessageDelay); 
      }

      default: {
        throw new Error('Unhandled ConvoPhase:', convoPhase);
      }
    }
  }, [
    convoPhase, goToPromptPhase, lastHumanAnswer,
    addMessage, sendMessage, hasModalOpened,
    prefill, setPrefill, navigateToCreate,
  ]);

  return (
    <ConversationContext.Provider value={{...conversation, isAiThinking}}>
      <Flex direction='column' {...boxProps}>
        <MessageBox 
          flexGrow={1}
          border='none'
          cursor='pointer'
          onClick={() => {toggleModal(true);}}
        />
      </Flex>
      <Modal
        onClose={() => {toggleModal(false);}}
        finalFocusRef={finalFocusRef}
        isOpen={isModalOpen}
        scrollBehavior='inside'
      >
        <ModalOverlay />
        <ConversationModalContent />
      </Modal>
    </ConversationContext.Provider>
  );
}

function setTimeoutWithCleanup(callback: Function, duration: number): () => void {
  const timerId = setTimeout(callback, duration);
  return () => {
    clearTimeout(timerId);
  }
}

function isValidEmail(emailInput: string): boolean {
  const emailRegex = /^[+a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  return emailRegex.test(emailInput);
}
