import React, { PropsWithChildren, useContext, useState } from "react";
import { collection, limit, query, where} from 'firebase/firestore';
import { useCollectionData } from 'react-firebase-hooks/firestore';

import {
  ActionTypes,
  ExploreEducationActionDocument,
  exploreEducationActionConverter,
  RegisterDomainActionDocument,
  registerDomainActionConverter,
  ShareSocialMediaPostActionDocument,
  shareSocialMediaPostActionConverter,
  BusinessTier,
} from 'src/db'; 
import { logger, LogSource } from 'src/util/logger';

import {
  FirestoreContext, ActionsContext, ActionsContextProperties, unavailableActionContext,
} from './contexts';
import { useBusiness, useBusinessId } from './hooks';

const brandActions = 'brandActions';

export function ActionsProvider({children}: PropsWithChildren) {
  const businessId = useBusinessId();
  return (
    !businessId ?
    <ActionsContext.Provider value={unavailableActionContext}>
      {children}
    </ActionsContext.Provider> :
    <ActionsProviderInner businessId={businessId}>
      {children}
    </ActionsProviderInner>
  );
}

interface ActionsProviderInnerProps {
  businessId: string;
}
export function ActionsProviderInner({
  businessId, children
}: PropsWithChildren<ActionsProviderInnerProps>) {
  const fs = useContext(FirestoreContext);
  const now = new Date();
  const localNow = new Date(now.getTime() - now.getTimezoneOffset() * 60000); // Adjusted for local time zone
  const [ selectedDate, setSelectedDate ] = useState<Date>(localNow);
  const startOfDate = toUTCMidnight(selectedDate);
  const endOfDate = new Date(startOfDate);
  endOfDate.setUTCDate(startOfDate.getUTCDate() + 1);

  const business = useBusiness();

  const businessIdFilter = where('businessId', '==', businessId);
  const noDateFilter = where('hasAvailability', '==', false);
  const dateFilters = [
    where('hasAvailability', '==', true),
    // where('availableDate', '<=', startOfDate.getUTCMilliseconds()),
    where('dueDate', '>=', startOfDate),
    where('dueDate', '<=', endOfDate),
  ]
  const next3Days = new Date(startOfDate);
  next3Days.setUTCDate(startOfDate.getUTCDate() + 2);
  const nextFewDaysDateFilters = [
    where('hasAvailability', '==', true),
    where('dueDate', '>=', startOfDate),
    where('dueDate', '<=', next3Days),
  ]

  const [
    exploreEducationCollection, exploreEducationIsLoading, exploreEducationError,
  ] = useCollectionData<ExploreEducationActionDocument>(
    query<ExploreEducationActionDocument>(
      collection(fs, brandActions)
        .withConverter<ExploreEducationActionDocument>(exploreEducationActionConverter),
      where('brandActionType', '==', ActionTypes.exploreEducation),
      businessIdFilter, noDateFilter,
    )
  );

  const [
    exploreEducationDatedCollection, exploreEducationDatedIsLoading, exploreEducationDatedError,
  ] = useCollectionData<ExploreEducationActionDocument>(
    query<ExploreEducationActionDocument>(
      collection(fs, brandActions)
        .withConverter<ExploreEducationActionDocument>(exploreEducationActionConverter),
      where('brandActionType', '==', ActionTypes.exploreEducation),
      businessIdFilter, ...dateFilters,
    )
  );

  const [
    registerDomainCollection, registerDomainIsLoading, registerDomainError,
  ] = useCollectionData<RegisterDomainActionDocument>(
    query<RegisterDomainActionDocument>(
      collection(fs, brandActions)
        .withConverter<RegisterDomainActionDocument>(registerDomainActionConverter),
      where('brandActionType', '==', ActionTypes.registerDomain),
      businessIdFilter, noDateFilter,
    )
  );

  const [
    registerDomainDatedCollection, registerDomainDatedIsLoading, registerDomainDatedError,
  ] = useCollectionData<RegisterDomainActionDocument>(
    query<RegisterDomainActionDocument>(
      collection(fs, brandActions)
        .withConverter<RegisterDomainActionDocument>(registerDomainActionConverter),
      where('brandActionType', '==', ActionTypes.registerDomain),
      businessIdFilter, ...dateFilters,
    )
  );

  const [
    shareSocialMediaPostCollection, shareSocialMediaPostIsLoading, shareSocialMediaPostError,
  ] = useCollectionData<ShareSocialMediaPostActionDocument>(
    query<ShareSocialMediaPostActionDocument>(
      collection(fs, brandActions)
        .withConverter<ShareSocialMediaPostActionDocument>(shareSocialMediaPostActionConverter),
      where('brandActionType', '==', ActionTypes.shareSocialMediaPost),
      businessIdFilter, noDateFilter,
    )
  );

  const [
    shareSocialMediaPostDatedCollection, shareSocialMediaPostDatedIsLoading, shareSocialMediaPostDatedError,
  ] = useCollectionData<ShareSocialMediaPostActionDocument>(
    query<ShareSocialMediaPostActionDocument>(
      collection(fs, brandActions)
        .withConverter<ShareSocialMediaPostActionDocument>(shareSocialMediaPostActionConverter),
      where('brandActionType', '==', ActionTypes.shareSocialMediaPost),
      businessIdFilter, ...dateFilters,
    )
  );

  const [
    shareSocialMediaPostPreviewCollection, shareSocialMediaPostPreviewIsLoading, shareSocialMediaPostPreviewError,
  ] = useCollectionData<ShareSocialMediaPostActionDocument>(
    query<ShareSocialMediaPostActionDocument>(
      collection(fs, brandActions)
        .withConverter<ShareSocialMediaPostActionDocument>(shareSocialMediaPostActionConverter),
      where('brandActionType', '==', ActionTypes.shareSocialMediaPost),
      businessIdFilter, ...(business?.tier !== BusinessTier.FREE ? nextFewDaysDateFilters : []),
      ...(business?.tier === BusinessTier.FREE ? [limit(3)] : []),
    )
  );


  const providedValue: ActionsContextProperties = {
    [ActionTypes.exploreEducation]: {
      collection: [
        ...(exploreEducationCollection || []),
        ...(exploreEducationDatedCollection || []),
      ],
      isLoading: exploreEducationIsLoading || exploreEducationDatedIsLoading,
      error: exploreEducationError || exploreEducationDatedError,
    },
    [ActionTypes.registerDomain]: {
      collection: [
        ...(registerDomainCollection || []),
        ...(registerDomainDatedCollection || []),
      ],
      isLoading: registerDomainIsLoading || registerDomainDatedIsLoading,
      error: registerDomainError || registerDomainDatedError,
    },
    [ActionTypes.shareSocialMediaPost]: {
      collection: [
        ...(shareSocialMediaPostCollection || []),
        ...(shareSocialMediaPostDatedCollection || []),
      ],
      isLoading: shareSocialMediaPostIsLoading || shareSocialMediaPostDatedIsLoading,
      error: shareSocialMediaPostError || shareSocialMediaPostDatedError,
    },
    [ActionTypes.shareSocialMediaPostPreview]: {
      collection: [
        ...(shareSocialMediaPostPreviewCollection || []),
        ...(shareSocialMediaPostCollection || [])
      ],
      isLoading: shareSocialMediaPostPreviewIsLoading || shareSocialMediaPostIsLoading,
      error: shareSocialMediaPostPreviewError || shareSocialMediaPostError,
    },
    selectedDate,
    setSelectedDate,
  };

  if (exploreEducationError) {logger.error(LogSource.FIRESTORE, 'exploreEducationError:', exploreEducationError);}
  if (exploreEducationDatedError) {logger.error(LogSource.FIRESTORE, 'exploreEducationDatedError:', exploreEducationDatedError);}
  if (registerDomainError) {logger.error(LogSource.FIRESTORE, 'registerDomainError:', registerDomainError);}
  if (registerDomainDatedError) {logger.error(LogSource.FIRESTORE, 'registerDomainDatedError:', registerDomainDatedError);}
  if (shareSocialMediaPostError) {logger.error(LogSource.FIRESTORE, 'shareSocialMediaPostError:', shareSocialMediaPostError);}
  if (shareSocialMediaPostDatedError) {logger.error(LogSource.FIRESTORE, 'shareSocialMediaPostDatedError:', shareSocialMediaPostDatedError);}

  return (
    <ActionsContext.Provider value={providedValue}>
      {children}
    </ActionsContext.Provider>
  );
}

function toUTCMidnight(localDate: Date): Date {
  const year = localDate.getFullYear();
  const month = localDate.getMonth();
  const day = localDate.getDate();
  return new Date(Date.UTC(year, month, day));
}
