import React, { SyntheticEvent, useCallback, useRef, useState } from "react";

import {
  Card,
  CardHeader,
  CardBody,
  Text,
  VStack,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  IconButton,
  Icon,
  Tooltip,
  Textarea,
  Button,
  Flex,
  Spacer,
  FormControl,
  Center,
  ButtonProps,
  useDisclosure,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
} from "@chakra-ui/react";
import { TbEditCircle } from "react-icons/tb";

import { BrandKitProgress } from "src/components";
import { useBusiness, useBusinessId } from "src/db";
import { useApi } from "src/api";

import { HeaderFooterPage } from "./wrappers/HeaderFooterPage";
import { RequireBusinessWithSignup } from "./wrappers/RequireBusiness";
import { Path } from "src/nav";

import { BrandKitCard } from "./brandkit/BrandKitCard";
import { AudienceCard } from "./brandkit/AudienceCard";
import { NameCard } from "./brandkit/NameCard";
import { ColorsCard } from "./brandkit/ColorsCard";
import { FontCard } from "./brandkit/FontCard";
import { LogoCard } from "./brandkit/LogoCard";
import { SloganCard } from "./brandkit/SloganCard";
import { ValueCard } from "./brandkit/ValueCard";
import { BrandStoryCard } from "./brandkit/BrandStoryCard";

import {
  UpdateBusinessDescriptionRequest,
  UpdateBusinessDescriptionResponse,
} from "src/api/messages/UpdateBusinessDescription";
import { UpdateBusinessDifferentiatorRequest } from "src/api/messages/UpdateBusinessDifferentiator";
import { useMandatoryAssetsCompleted } from "../components/BrandKitProgress";

export function BrandKitPage() {
  return (
    <HeaderFooterPage pageTitle="Brand Kit" backTo={Path.brand}>
      <RequireBusinessWithSignup>
        <BrandKitPageContent />
      </RequireBusinessWithSignup>
    </HeaderFooterPage>
  );
}

function BrandKitPageContent() {
  const businessDescription = useBusiness()!.description;
  const businessDifferentiator = useBusiness()!.businessDifferentiator || "";
  const mandatoryAssetsCompleted = useMandatoryAssetsCompleted();

  const {
    isOpen: showFullTextPopover,
    onOpen: openPopover,
    onClose: closePopover,
  } = useDisclosure();

  return (
    <>
      <VStack direction="column" align="center" mx={4}>
        <BrandKitProgress width="100%" mb={8} />
      </VStack>

      <AudienceCard mb={12} />
      <FontCard mb={12} />
      <LogoCard mb={12} />
      <NameCard mb={12} />
      <SloganCard mb={12} />
      <ColorsCard mb={12} />
      <ValueCard mb={12} />
      <BrandStoryCard mb={12} />
      {/* Business Differentiator Card */}
      <Card px={BrandKitCard.xPadding} py={BrandKitCard.yPadding} mb={12}>
        <CardHeader
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Text as="h2" textStyle="titleForSection" mb={4}>
            Business Differentiator
          </Text>
          {/* TODO: Add edit button if API allows */}
        </CardHeader>
        <CardBody mb={4}>
          {businessDifferentiator && !mandatoryAssetsCompleted ? (
            <Popover>
              <PopoverTrigger>
                <Text textStyle="bodyLarge" noOfLines={3} cursor="pointer">
                  {businessDifferentiator}
                </Text>
              </PopoverTrigger>
              <PopoverContent>
                <PopoverBody>
                  <Text textStyle="bodyLarge">{businessDifferentiator}</Text>
                </PopoverBody>
              </PopoverContent>
            </Popover>
          ) : (
            "Differentiator not provided."
          )}
          <UpdateBusinessDifferentiatorButton
            businessDifferentiator={businessDifferentiator}
            ml={2}
          />
        </CardBody>
      </Card>
      {/* Business Idea Card */}
      <Card px={BrandKitCard.xPadding} py={BrandKitCard.yPadding} mb={12}>
        <CardHeader
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Text as="h2" textStyle="titleForSection" mb={4}>
            Business Idea
          </Text>
          {/* TODO: Add edit button if API allows */}
        </CardHeader>
        <CardBody mb={4}>
          <Popover
            isOpen={showFullTextPopover}
            onOpen={openPopover}
            onClose={closePopover}
            placement="top-end"
          >
            <PopoverTrigger>
              <Text textStyle="bodyLarge" noOfLines={3} cursor="pointer">
                {businessDescription}
              </Text>
            </PopoverTrigger>
            <PopoverContent>
              <PopoverBody>
                <Text textStyle="bodyLarge">{businessDescription}</Text>
              </PopoverBody>
            </PopoverContent>
            {!mandatoryAssetsCompleted && (
              <UpdateBusinessDescriptionButton
                businessDescription={businessDescription}
                ml={2}
              />
            )}
          </Popover>
        </CardBody>
      </Card>
    </>
  );
}

export interface UpdateBusinessDescriptionButtonProp extends ButtonProps {
  businessDescription: string;
}

export interface UpdateBusinessDifferentiatorButtonProp extends ButtonProps {
  businessDifferentiator: string;
}

function UpdateBusinessDescriptionButton({
  businessDescription,
  ...buttonProps
}: UpdateBusinessDescriptionButtonProp) {
  const inputRef = useRef<HTMLTextAreaElement>(null);
  const businessId = useBusinessId()!;
  const [api, surfaceKnownErrors] = useApi();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [textValue, setTextValue] = useState<string>(businessDescription);

  const handleSubmit = useCallback(
    (text: string) => {
      if (isSubmitting) {
        return;
      }
      if (text === businessDescription || !text) {
        onClose();
        return;
      }
      setIsSubmitting(true);
      const updateRequestData: UpdateBusinessDescriptionRequest = {
        businessId: businessId,
        businessDescription: text,
      };
      api
        .updateBusinessDescription(updateRequestData, surfaceKnownErrors)
        .then((response: UpdateBusinessDescriptionResponse) => {
          onClose();
        })
        .finally(() => {
          setIsSubmitting(false);
        });
    },
    [
      isSubmitting,
      businessDescription,
      businessId,
      api,
      surfaceKnownErrors,
      onClose,
    ]
  );

  return (
    <>
      <Tooltip label="Update" placement="top">
        <IconButton
          aria-label="Update Business Description"
          color="secondary.dark"
          icon={<Icon as={TbEditCircle} boxSize="24px" />}
          height="fit-content"
          {...buttonProps}
          onClick={(e: SyntheticEvent) => {
            e.stopPropagation();
            onOpen();
          }}
        />
      </Tooltip>

      <Modal
        isOpen={isOpen}
        onClose={onClose}
        isCentered={true}
        initialFocusRef={inputRef}
      >
        <ModalOverlay />
        <ModalContent m={4}>
          <ModalHeader>
            <Center>
              <Text textStyle="bodyLarge">{`Update Business Description`}</Text>
            </Center>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <form
              onSubmit={(e) => {
                // Prevent page refresh
                e.preventDefault();
                if (!!textValue) {
                  handleSubmit(textValue);
                }
              }}
            >
              <Flex
                direction="row"
                align="center"
                justify="space-between"
                wrap="wrap"
              >
                <FormControl flex="1 0 fit-content">
                  <Textarea
                    ref={inputRef}
                    placeholder="Type here"
                    variant="filled"
                    value={textValue}
                    onChange={(e) => {
                      setTextValue(e.target.value);
                    }}
                    isDisabled={isSubmitting}
                  />
                </FormControl>
                <Spacer flex="0 0 8px" />
                <Button
                  type="submit"
                  variant="fill"
                  colorScheme="primary"
                  size="sm"
                  flex="0 0 fit-content"
                  isDisabled={!textValue}
                  isLoading={isSubmitting}
                >
                  Save
                </Button>
              </Flex>
            </form>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

function UpdateBusinessDifferentiatorButton({
  businessDifferentiator,
  ...buttonProps
}: UpdateBusinessDifferentiatorButtonProp) {
  const inputRef = useRef<HTMLTextAreaElement>(null);
  const businessId = useBusinessId()!;
  const [api, surfaceKnownErrors] = useApi();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [textValue, setTextValue] = useState<string>(businessDifferentiator);

  const handleSubmit = useCallback(
    (text: string) => {
      if (isSubmitting) {
        return;
      }
      if (text === businessDifferentiator || !text) {
        onClose();
        return;
      }
      setIsSubmitting(true);
      const updateRequestData: UpdateBusinessDifferentiatorRequest = {
        businessId: businessId,
        businessDifferentiator: text,
      };
      api
        .updateBusinessDifferentiator(updateRequestData, surfaceKnownErrors)
        .then(() => {
          onClose();
        })
        .finally(() => {
          setIsSubmitting(false);
        });
    },
    [
      isSubmitting,
      businessDifferentiator,
      businessId,
      api,
      surfaceKnownErrors,
      onClose,
    ]
  );

  return (
    <>
      <Tooltip label="Update" placement="top">
        <IconButton
          aria-label="Update Business Differentiator"
          color="secondary.dark"
          icon={<Icon as={TbEditCircle} boxSize="24px" />}
          height="fit-content"
          {...buttonProps}
          onClick={(e: SyntheticEvent) => {
            e.stopPropagation();
            onOpen();
          }}
        />
      </Tooltip>

      <Modal
        isOpen={isOpen}
        onClose={onClose}
        isCentered={true}
        initialFocusRef={inputRef}
      >
        <ModalOverlay />
        <ModalContent m={4}>
          <ModalHeader>
            <Center>
              <Text textStyle="bodyLarge">{`Update Business Differentiator`}</Text>
            </Center>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <form
              onSubmit={(e) => {
                // Prevent page refresh
                e.preventDefault();
                if (!!textValue) {
                  handleSubmit(textValue);
                }
              }}
            >
              <Flex
                direction="row"
                align="center"
                justify="space-between"
                wrap="wrap"
              >
                <FormControl flex="1 0 fit-content">
                  <Textarea
                    ref={inputRef}
                    placeholder="Type here"
                    variant="filled"
                    value={textValue}
                    onChange={(e) => {
                      setTextValue(e.target.value);
                    }}
                    isDisabled={isSubmitting}
                  />
                </FormControl>
                <Spacer flex="0 0 8px" />
                <Button
                  type="submit"
                  variant="fill"
                  colorScheme="primary"
                  size="sm"
                  flex="0 0 fit-content"
                  isDisabled={!textValue}
                  isLoading={isSubmitting}
                >
                  Save
                </Button>
              </Flex>
            </form>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
