import React, { SyntheticEvent, useCallback, useEffect, useState } from "react";
import { UserCredential, getRedirectResult } from "firebase/auth";

import {
  Avatar,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  BoxProps,
  Button,
  Card,
  CardHeader,
  CardBody,
  IconButton,
  Text,
  TextProps,
  useToast,
  Box,
  Image,
} from "@chakra-ui/react";

import { ApiMethods, useApi, useApiPending } from "src/api";
import { ToUpdateProfileInfo } from "src/api/messages/UpdateProfile";
import { useAuth, useUser } from "src/auth";
import {
  BusinessTier,
  useProfileId,
  useProfileInfo,
  useBusinessTier,
} from "src/db";
import { Path, useNavigateWithParams } from "src/nav";
import { useEffectLate } from "src/util/async";
import { logger, LogSource } from "src/util/logger";

import { HeaderFooterPage } from "./wrappers/HeaderFooterPage";
import { RequireProfile } from "./wrappers/RequireProfile";

import { ArrowRight, UsersGroup } from "src/images";

export function SettingsPage() {
  return (
    <HeaderFooterPage pageTitle="Settings">
      <RequireProfile excludeAnonymous={true} OrElse={<NoAccount />}>
        <SettingsPageContent />
      </RequireProfile>
    </HeaderFooterPage>
  );
}

function NoAccount() {
  return (
    <Alert status="error">
      <AlertIcon />
      <AlertTitle>Account Not Found</AlertTitle>
      <AlertDescription>
        No user is logged in, or no profile was created
      </AlertDescription>
    </Alert>
  );
}

function SettingsPageContent() {
  const profileInfo = useProfileInfo()!;
  const businessTier = useBusinessTier()!;
  const displayName = profileInfo.name || "";
  const avatarDisplayName = profileInfo.name || "";
  const photoUrl = profileInfo.profileImageInfo?.imageUrl || "";
  const navigate = useNavigateWithParams();

  const goToCredits = useCallback(
    (e: SyntheticEvent) => {
      e.stopPropagation();
      navigate({ to: Path.credits });
    },
    [navigate]
  );

  return (
    <>
      <UpdateProfileOnRedirectResult />
      <Card px="3" mt={2} mb={4} borderRadius="24px">
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          onClick={() => navigate({ to: Path.members })}
          cursor="pointer"
        >
          <Box display="flex" alignItems="center" gap="8px">
            <IconButton
              icon={
                <Image src={UsersGroup} alt="edit permissions" height="32px" />
              }
              aria-label="Menu"
              bg={"transparent"}
            />
            <Text textStyle="body">Edit Permissions</Text>
          </Box>
          <Box>
            <IconButton
              icon={
                <Image src={ArrowRight} alt="edit permissions" height="20px" />
              }
              aria-label="Menu"
              bg={"transparent"}
            />
          </Box>
        </Box>
      </Card>
      <Card p={4} mb={8}>
        <CardHeader display="flex" flexDirection="row" alignItems="flex-start">
          <Text flex="1 0 0" textStyle="display4" mt={4}>
            Hi {displayName}
          </Text>
          <Avatar
            boxSize="80px"
            name={avatarDisplayName}
            src={photoUrl}
            ml={2}
          />
        </CardHeader>
        <CardBody>
          <Text textStyle="body">Current Plan</Text>
          <Card
            p={2}
            mb={2}
            variant="outline"
            bg="primary.veryLight"
            border="1px solid"
            borderColor="primary.dark"
          >
            <PlanDetails tier={businessTier} />
          </Card>
        </CardBody>
        <Button colorScheme="primary" variant="outline" onClick={goToCredits}>
          View or Add Credits
        </Button>
      </Card>
    </>
  );
}

interface PlanDetailsProps {
  tier: BusinessTier;
}

function PlanDetails({ tier }: PlanDetailsProps) {
  const headerProps: BoxProps = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "baseline",
    mb: 1,
  };

  const inlineProps: TextProps = {
    as: "span",
    display: "inline",
    textStyle: "bodySmall",
  };

  const bodyProps: BoxProps = {
    textStyle: "body2",
  };

  switch (tier) {
    case BusinessTier.FREE:
      return (
        <>
          <CardHeader {...headerProps}>
            <Text textStyle="titleForGroup">Free Trial</Text>
            <Text textStyle="displayPrice">No Cost</Text>
          </CardHeader>
          <CardBody {...bodyProps}>
            Explore our Brand Kit offering & get 25 assets for free
          </CardBody>
        </>
      );
    case BusinessTier.PAY_AS_YOU_GO:
      return (
        <>
          <CardHeader {...headerProps}>
            <Text textStyle="titleForGroup">Brand Lite</Text>
            <Text textStyle="displayPrice">
              $250&nbsp;<Text {...inlineProps}>flat fee</Text>
            </Text>
          </CardHeader>
          <CardBody {...bodyProps}>
            Define the strategic fundamentals of your brand with our AI-powered
            agent
          </CardBody>
        </>
      );
    case BusinessTier.SUBSCRIPTION:
      return (
        <>
          <CardHeader {...headerProps}>
            <Text textStyle="titleForGroup">Brand Turbo</Text>
            <Text textStyle="displayPrice">
              $40&nbsp;<Text {...inlineProps}>monthly subscription</Text>
            </Text>
          </CardHeader>
          <CardBody {...bodyProps}>
            Brief language here that motivates the user to subscribe to this
            plan
          </CardBody>
        </>
      );
    default:
      logger.error(LogSource.FIRESTORE, `Unknown business tier: ${tier}`);
      return <></>;
  }
}

function UpdateProfileOnRedirectResult() {
  const [auth] = useAuth();
  const [credential, setCredential] = useState<UserCredential | null>(null);
  const [api, surfaceKnownErrors] = useApi();
  const isUpdatingProfile = useApiPending(ApiMethods.UPDATE_PROFILE);
  const user = useUser(true);
  const profileId = useProfileId();
  const profileInfo = useProfileInfo();
  const canUpdateProfile =
    !isUpdatingProfile &&
    !!user &&
    !!profileId &&
    !!profileInfo &&
    !!credential;
  const toast = useToast();

  const updateProfileFromCredential = useCallback(() => {
    if (!canUpdateProfile) {
      return;
    }
    const newInfo: ToUpdateProfileInfo = {};
    if (!!user.displayName && !profileInfo?.name) {
      newInfo.name = user.displayName;
    }
    if (!!user.photoURL && !profileInfo?.profileImageInfo?.imageUrl) {
      newInfo.profileImageUrl = user.photoURL;
    }
    if (!Object.keys(newInfo).length) {
      return;
    }
    api
      .updateProfile(
        {
          profileId,
          toUpdateProfileInfo: newInfo,
        },
        surfaceKnownErrors
      )
      .then(() => {
        toast({
          status: "info",
          description: "Updated profile!",
        });
      });
  }, [
    api,
    surfaceKnownErrors,
    canUpdateProfile,
    user,
    profileInfo,
    profileId,
    toast,
  ]);

  useEffect(() => {
    getRedirectResult(auth).then((cred: UserCredential | null) => {
      setCredential(cred);
    });
  }, [auth]);
  useEffectLate(updateProfileFromCredential);

  return <></>;
}
