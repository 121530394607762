import {
  Avatar,
  Box,
  IconButton,
  Image,
  MenuItem,
  MenuList,
  Text,
} from "@chakra-ui/react";
import { useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { BusinessContext, useBusiness } from "src/db";
import { BusinessWithDetails } from "src/db/model/profileBusiness";
import { Check, Plus } from "src/images";
import { Path, SearchParam } from "src/nav";

interface IBrandSwitcherDropdownProps {
  businesses?: BusinessWithDetails[];
  width?: string;
}

const BrandSwitcherDropdown = ({
  businesses,
  width,
}: IBrandSwitcherDropdownProps) => {
  const { setSelectedBusinessId } = useContext(BusinessContext);
  const business = useBusiness();
  const navigate = useNavigate();

  const handleBrandSwitch = (businessId: string) => {
    setSelectedBusinessId(businessId);
    navigate(Path.home);
  }

  return (
    <MenuList
      zIndex={999999}
      top="70px"
      boxShadow="lg"
      borderRadius="24px"
      bg="grayscale.offwhite"
      w={width || "250px"}
    >
      {businesses &&
        businesses.map((businessData, i) => (
          <MenuItem
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            borderBottomColor="gray.lightest"
            borderBottomWidth={
              businesses.length > 1 && i + 1 !== businesses.length
                ? "1px"
                : undefined
            }
            borderStyle="solid"
            px="4"
            py="2"
            height="60px"
            key={businessData.id}
            onClick={() => handleBrandSwitch(businessData.businessId)}
          >
            <Box display="flex" gap="16px" alignItems="center">
              <Box>
                <Avatar
                  name={businessData?.assetName?.assetData?.assetValue?.name}
                  src={
                    businessData?.assetLogo?.assetData?.assetValue?.logo
                      ?.imageInfo?.imageUrl
                  }
                  boxSize="32px"
                  size="sm"
                />
              </Box>
              <Box>
                <Text
                  textStyle="body"
                  fontWeight="bold"
                  w="100px"
                  textOverflow="ellipsis"
                  whiteSpace="nowrap"
                  overflow="hidden"
                >
                  {businessData?.assetName?.assetData?.assetValue?.name}
                </Text>
                {businessData.credits !== null && (
                  <Text color="gray.medium" fontSize="13px">
                    {businessData.credits} credits
                  </Text>
                )}
              </Box>
            </Box>
            {businessData.businessId === business?.id && (
              <Box>
                <IconButton
                  icon={
                    <Image src={Check} alt="selected brand" height="22px" />
                  }
                  aria-label="selected brand"
                  bg={"transparent"}
                />
              </Box>
            )}
          </MenuItem>
        ))}
      <Link to={`${Path.urlOnboarding}?${SearchParam.newBusiness}=true`}>
        <MenuItem
          display="flex"
          gap="16px"
          alignItems="center"
          height="60px"
          borderTopWidth="1px"
          borderTopColor="gray.light"
        >
          <IconButton
            icon={<Image src={Plus} alt="create brand" boxSize="32px" />}
            aria-label="create brand"
            bg={"transparent"}
            ml="4"
          />
          <Text textStyle="body" fontWeight="bold">
            Create a new brand
          </Text>
        </MenuItem>
      </Link>
    </MenuList>
  );
};

export default BrandSwitcherDropdown;
