import React, { useContext, useRef } from 'react';

import {
  Box,
  IconButton,
  Image,
  Modal,
  ModalOverlay,
  ResponsiveValue,
  VStack,
} from '@chakra-ui/react'

import { avatar } from 'src/images';
import { appMaxWidth } from 'src/util/constants';

import { ConversationModalContent } from './chat/ConversationModalContent';
import { ConversationContext } from './chat/context';

/**
 * Permanent chat component available everywhere business is available.
 */

interface IProps {
  position?: ResponsiveValue<any>;
  boxSize?: string;
  bottom?: string;
  width?: string;
  margin?: string;
}

export function PermanentChat({ position = 'fixed', boxSize = '64px', bottom = '0', width = "100%", margin = "16px" }: IProps) {
  const fabRef = useRef<HTMLButtonElement>(null);
  const conversation = useContext(ConversationContext);
  if (Object.keys(conversation).length === 0) {
    // The user is not signed in.
    return <></>
  } else {
    return (
      <VStack spacing="1" align="center">
        <Box
          display='flex'
          flexDirection='row'
          justifyContent='flex-end'
          position={position}
          bottom={bottom}
          width={width}
          maxWidth={appMaxWidth}
          margin='0 auto'
        >
          <IconButton
            aria-label='Open Chat'
            ref={fabRef}
            mb={margin}
            mr={margin}
            bg='transparent'
            boxSize='fit-content'
            onClick={() => { conversation.toggleModal(true); }}
            icon={
              <Image
                src={avatar}
                alt='Talawa'
                boxSize={boxSize}
              />
            }
          />
        </Box>
        <Modal
          onClose={() => { conversation.toggleModal(false); }}
          finalFocusRef={fabRef}
          isOpen={conversation.isModalOpen}
          scrollBehavior='inside'>
          <ModalOverlay />
          <ConversationModalContent />
        </Modal>
      </VStack>);
  }
}
