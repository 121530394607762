import React, { SyntheticEvent, useState, useEffect } from 'react';

import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Card,
  CardHeader,
  CardBody,
  Collapse,
  HStack,
  Icon,
  Text,
  VStack,
} from '@chakra-ui/react';

import { TiArrowBack } from "react-icons/ti";
import { MdCheckCircleOutline, MdOutlineMenuOpen } from 'react-icons/md';

import { useApi } from 'src/api';
import { useSelectedDate } from 'src/db';
import { DatePicker } from 'src/components';
import { ExploreEducation, RegisterDomain, ShareSocialMediaPost } from 'src/components/actions';
import {
  ActionTypes,
  ExploreEducationActionDocument,
  RegisterDomainActionDocument,
  ShareSocialMediaPostActionDocument,
  useBusinessId, useIsBrandKitLocked,
  useActionCollection,
  ActionDocument,
} from 'src/db';

import { HeaderFooterPage } from "./wrappers/HeaderFooterPage";
import { RequireBusinessWithSignup } from "./wrappers/RequireBusiness";
import { Path, useNavigateWithParams } from "src/nav";
import { AddSocialMediaAssetButton } from "../components/AddSocialMediaAsset";

export function BrandActionsPage() {
  return (
    <HeaderFooterPage
      pageTitle="Brand Actions"
      backTo={Path.home}
      banner={<Box
        position='absolute'
        top={0} left={0} right={0}
        bg='primary.dark'
        width='100%' height={36}
      ></Box>}
    >
      <RequireBusinessWithSignup>
        <BrandActionsPageContent />
      </RequireBusinessWithSignup>
    </HeaderFooterPage>
  );
}

function BrandActionsPageContent() {
  const shareActions = useActionCollection<ShareSocialMediaPostActionDocument>(ActionTypes.shareSocialMediaPost);
  const setupActions = useActionCollection<RegisterDomainActionDocument>(ActionTypes.registerDomain);
  const exploreActions = useActionCollection<ExploreEducationActionDocument>(ActionTypes.exploreEducation);

  const [isShareActionsDone, setIsShareActionsDone] = useState(false);
  const [isShareActionsExpanded, setIsShareActionsExpanded] = useState(true);

  const [isSetupActionsDone, setIsSetupActionsDone] = useState(false);
  const [isSetupActionsExpanded, setIsSetupActionsExpanded] = useState(true);

  const [isExploreActionsDone, setIsExploreActionsDone] = useState(false);
  const [isExploreActionsExpanded, setIsExploreActionsExpanded] = useState(true);


  useEffect(() => {
    if (isActionsDone(exploreActions)) {
      setIsExploreActionsDone(true);
      setIsExploreActionsExpanded(false);
    } else {
      setIsExploreActionsDone(false);
      setIsExploreActionsExpanded(true);
    }
    if (isActionsDone(setupActions)) {
      setIsSetupActionsDone(true);
      setIsSetupActionsExpanded(false);
    } else {
      setIsSetupActionsDone(false);
      setIsSetupActionsExpanded(true);
    }
    if (isActionsDone(shareActions)) {
      setIsShareActionsDone(true);
      setIsShareActionsExpanded(false);
    } else {
      setIsShareActionsDone(false);
      setIsShareActionsExpanded(true);
    }
  }, [exploreActions, setupActions, shareActions]);

  const isActionsDone = (actions: ActionDocument[]) => {
    for (const action of actions) {
      if (action.isCompleted) {
        return true;
      }
    }
  }

  const toggleExploreActionsCollapse = () => {
    setIsExploreActionsExpanded(!isExploreActionsExpanded);
  };
  const toggleSetupActionsCollapse = () => {
    setIsSetupActionsExpanded(!isSetupActionsExpanded);
  }
  const toggleShareActionsCollapse = () => {
    setIsShareActionsExpanded(!isShareActionsExpanded);
  }

  return (
    <VStack align="stretch" position='relative' spacing={2}>
      
      <DatePicker height={12} width='100%' mt={2}/>
      <Card mb={8} p={6}>
        <CardHeader mb={4}>
          <Text as="h2" textStyle="caption" align="left">Share</Text>
          <Text textStyle="bodyLarge" align="left">
            Announce your new brand to the world on social media
          </Text>
        </CardHeader>
        <CardBody>
          {shareActions.length >= 0 && isShareActionsDone && (<AllComplete onLabelClick={toggleShareActionsCollapse} label='All share actions are complete' />)}
          <Collapse in={isShareActionsExpanded}>
          {!!shareActions.length ? <>
            <Text
              textStyle="bodySmall"
              color="grayscale.dark"
              align="center" mb={4}
            >
              Tap a post to configure
            </Text>
            <VStack align="center">
              {shareActions.map((action) => {
                return <ShareSocialMediaPost
                          key={action.id}
                          post={action}
                          mb={6}
                          border={"0.1px solid"}
                          borderColor={action.isCompleted ? "grayscale.dark" : "secondary.dark"}
                          rounded="lg"
                          padding={"2"}
                          margin={"2"}
                          />;
              })}
              <SuggestMoreIfAvailable />
            </VStack>
            </> :
              <Text textStyle="bodySmall" color="grayscale.dark" align="center" mb={4}>
                No share actions available on this date
              </Text>
            }
          </Collapse>
        </CardBody>
      </Card>

      <Card mb={8} p={6}>
        <CardHeader mb={4}>
          <Text as="h2" textStyle="caption" align="left">Setup</Text>
        </CardHeader>
        <CardBody>
          {setupActions.length >= 0 && isSetupActionsDone && (<AllComplete onLabelClick={toggleSetupActionsCollapse} label='All setup actions are complete' />)}
          <Collapse in={isSetupActionsExpanded}>
          {!!setupActions.length ? <>
            <VStack align="center">
              {setupActions.map((action) => {
                return <RegisterDomain
                          key={action.id}
                          domain={action}
                          mb={6}
                          border={"0.1px solid"}
                          borderColor={action.isCompleted ? "grayscale.dark" : "secondary.dark"}
                          rounded="lg"
                          padding={"2"}
                          margin={"2"} />;
              })}
            </VStack>
          </> :
            <Text textStyle="bodySmall" color="grayscale.dark" align="center" mb={4}>
              No setup actions available on this date
            </Text>
          }
          </Collapse>
        </CardBody>
      </Card>

      <Card mb={8} p={6}>
        <CardHeader mb={4}>
          <Text as="h2" textStyle="caption" align="left">Explore</Text>
        </CardHeader>
        <CardBody>
          {exploreActions.length >= 0 && isExploreActionsDone && (<AllComplete onLabelClick={toggleExploreActionsCollapse} label='All explore actions are complete' />)}
          <Collapse in={isExploreActionsExpanded}>
            {!!exploreActions.length ? <>
              <VStack align="center">
                {exploreActions.map((action) => {
                  return <ExploreEducation
                            key={action.id}
                            article={action}
                            mb={6} 
                            border={"0.1px solid"}
                            borderColor={action.isCompleted ? "grayscale.dark" : "secondary.dark"}
                            rounded="lg"
                            padding={"2"}
                            margin={"2"} />;
                })}
              </VStack>
            </> :
              <Text textStyle="bodySmall" color="grayscale.dark" align="center" mb={4}>
                No setup actions available on this date
              </Text>
            }
          </Collapse>
        </CardBody>
      </Card>

      <Text textStyle="body2" mb={6} align="center">
        Brand actions are curated weekly to help you establish your brand.
      </Text>
    </VStack>
  );
}

/**
 * Creates a "Suggest More" button for social media posts,
 * only if the functionality to do so should be available.
 * 
 * If not available, it gives directions to the user in a warning/info message.
 */
function AllComplete({ label, onLabelClick }: {label: string, onLabelClick?: () => void}) {
  return (<HStack justify='left' align='end' onClick={onLabelClick}>
    <Icon
      as={MdCheckCircleOutline}
      aria-label='complete'
      color='secondary.dark'
      boxSize='24px'
    />
    <Text textStyle='bodyLarge' color='grayscale.dark'>{label}</Text>
    {onLabelClick && <Icon
      as={MdOutlineMenuOpen}
      aria-label='expand'
      color='grayscale.dark'
      boxSize='24px'
      ml={"auto"}
    />
    }
  </HStack>);
}

/**
 * Creates a "Suggest More" button for social media posts,
 * only if the functionality to do so should be available.
 * 
 * If not available, it gives directions to the user in a warning/info message.
 */
function SuggestMoreIfAvailable() {
  const [api, surfaceKnownErrors] = useApi();
  const businessId = useBusinessId()!;
  const isBrandKitLocked = useIsBrandKitLocked();
  const allPosts = useActionCollection<any>(ActionTypes.shareSocialMediaPost);
  const [isGenerating, setIsGenerating] = useState<boolean>(false);
  const navigate = useNavigateWithParams();
  const [, setSelectedDate] = useSelectedDate();

  const generateSocialMediaPost = (e: SyntheticEvent) => {
    e.stopPropagation();
    if (isGenerating) {return ;}
    setIsGenerating(true);
    api.createBrandActionSocialMediaPost({
      businessId,
    }, surfaceKnownErrors)
    .then(() => {
      // After generating, return user to Today's date
      setSelectedDate(new Date());
    } )
    .finally(() => {
      setIsGenerating(false);
    });
  }

  const handleClickAlert = (e: SyntheticEvent) => {
    e.stopPropagation();
    console.log('clicked alert');
    navigate({to: Path.brandkit});
  }

  // Only happens if user visited the Path directly through URL.
  if (!isBrandKitLocked) {
    return (
      <Alert status='warning' onClick={handleClickAlert}>
        <AlertIcon />
        Please finish your Brand Kit
        <TiArrowBack />
      </Alert>
    );

  // Only happens for the first few minutes after locking Brand Kit.
  } else if (!allPosts.length) {
    return (
      <Alert status='info'>
        <AlertIcon />
        Social media posts are still generating and will be available in a few minutes...
      </Alert>
    );
  
  } else {
    return (
      <>
      <Button
        variant='outline'
        colorScheme='secondary'
        isLoading={isGenerating}
        display='block'
        minWidth='50%'
        mx='auto'
        onClick={generateSocialMediaPost}
      >Suggest More</Button>
      <AddSocialMediaAssetButton>+&nbsp;Add Your Own</AddSocialMediaAssetButton>
      </>
    );
  }
}
