import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";

interface ModalProps {
  isOpen: boolean;
  handleClose: () => void;
}

const UserInvitationExpiredModal = ({ isOpen, handleClose }: ModalProps) => {
  return (
    <Modal isCentered isOpen={isOpen} onClose={handleClose} size="xs">
      <ModalOverlay bg="blackAlpha.500" />
      <ModalContent borderRadius="16px">
        <ModalHeader color="gray.dark" fontSize="18px">
          Invitation Expired
        </ModalHeader>
        <ModalCloseButton mt="4" mr="4" />
        <ModalBody>
          <Text textStyle="bodySmall" color="gray.medium" fontSize="13px">
            The invitation to join this team has either expired or been revoked by the owner. 
            Please contact the owner for more information.
          </Text>
        </ModalBody>
        <ModalFooter>
          <Box display="flex" flexDirection="column" width="100%" gap="4">
            <Button
              colorScheme="primary"
              onClick={handleClose}
              variant="outline"
              width="100%"
              borderRadius={7}
            >
              OK
            </Button>
          </Box>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default UserInvitationExpiredModal;
