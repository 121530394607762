import React, { FC } from "react";

import {
  BoxProps,
  List,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Tag,
  Text,
  useDisclosure,
} from "@chakra-ui/react";

import { AssetOriginIcon } from "src/components/assets";
import { AssetActions } from "src/components/assets/actions";
import { AssetDocument } from "src/db";

import { AssetsProps } from "./BrandKitCard";

import { UpdateTextAssetButtonProps } from "src/components/assets/updateAsset/UpdateTextAsset";

interface ActionableTextAssetsProps
  extends AssetsProps<AssetDocument>,
    BoxProps {}
export function ActionableTextAssets({
  assets,
  UpdateAssetButton,
  isLocked,
  ...boxProps
}: ActionableTextAssetsProps) {
  // Max height is about 3.5 rows.
  return (
    <List
      maxHeight="240px"
      overflowY="auto"
      display="flex"
      width="100%"
      flexDirection="column"
      alignItems="stretch"
      spacing={2}
    >
      {assets.map((asset: AssetDocument) => {
        return (
          <TextAsset
            key={asset.id}
            asset={asset}
            UpdateAssetButton={UpdateAssetButton}
          />
        );
      })}
    </List>
  );
}

interface TextAssetProps extends BoxProps {
  asset: AssetDocument;
  UpdateAssetButton?: FC<UpdateTextAssetButtonProps>;
}
function TextAsset({ asset, UpdateAssetButton, ...boxProps }: TextAssetProps) {
  const {
    isOpen: showFullTextPopover,
    onOpen: openPopover,
    onClose: closePopover,
  } = useDisclosure();

  return (
    <Popover
      isOpen={showFullTextPopover}
      onOpen={openPopover}
      onClose={closePopover}
      placement="top-end"
    >
      <Tag
        colorScheme={asset.selected ? "primary" : "secondary"}
        display="flex"
        {...boxProps}
      >
        <AssetOriginIcon origin={asset.origin} />
        &nbsp;
        <PopoverTrigger>
          <Text noOfLines={3} flex="1 0 0" cursor="pointer">
            {asset.renderLabel()}
          </Text>
        </PopoverTrigger>
        <PopoverContent>
          <PopoverBody>
            <Text flex="1 0 0">{asset.renderLabel()}</Text>
          </PopoverBody>
        </PopoverContent>
        <AssetActions
          UpdateAssetButton={UpdateAssetButton}
          asset={asset}
          ml={2}
        />
      </Tag>
    </Popover>
  );
}
