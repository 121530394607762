import {
  Box,
  Button,
  Card,
  CardBody,
  CardHeader,
  Progress,
  Text,
  VStack,
} from "@chakra-ui/react";
import { HeaderFooterPage } from "./wrappers/HeaderFooterPage";
import { RequireBusinessWithSignup } from "./wrappers/RequireBusiness";
import { Carousel } from "src/components";
import {
  getShortSummary,
  ReportDocument,
  ReportStatus,
} from "src/db/model/report";
import { SyntheticEvent, useCallback } from "react";
import { Path, useNavigateWithParams } from "src/nav";
import { useReportCollection } from "src/db/hooks";

export function CreatePage() {
  return (
    <HeaderFooterPage pageTitle="Create">
      <RequireBusinessWithSignup>
        <CreatePageContent />
      </RequireBusinessWithSignup>
    </HeaderFooterPage>
  );
}

function CreatePageContent() {
  const navigate = useNavigateWithParams();
  const reports = useReportCollection();

  const goToBrandReports = useCallback(
    (e: SyntheticEvent) => {
      e.stopPropagation();
      navigate({ to: Path.brandreports });
    },
    [navigate]
  );

  return (
    <>
      <Card mb={12} p={6} onClick={goToBrandReports}>
        <CardHeader mb={2}>
          <Text as="header" textStyle="titleForSection" align="center" mb={2}>
            Brand Reports
          </Text>
        </CardHeader>
        <CardBody mb={2}>
          <VStack align="center">
            <Text textStyle="body" align="center" mb={2} pl={0.3} pr={0.3}>
              Discover your next big thing
            </Text>
            <ReportsCarousel reports={reports} />
          </VStack>
        </CardBody>
        <VStack align="center">
          {!!reports.filter((r) => r.status === ReportStatus.READY).length ? (
            <Button
              colorScheme="primary"
              variant="fill"
              minWidth="200px"
              mx="auto"
              onClick={goToBrandReports}
            >
              See Reports
            </Button>
          ) : (
            reports.length === 0 && (
              <Button
                colorScheme="primary"
                variant="fill"
                minWidth="200px"
                mx="auto"
              >
                Generate a Report
              </Button>
            )
          )}
        </VStack>
      </Card>

      <Card mb={12} p={6}>
        <CardHeader mb={2}>
          <Text as="header" textStyle="titleForSection" align="center" mb={2}>
            Brand Creations
          </Text>
        </CardHeader>
        <CardBody mb={2}>
          <VStack align="center">
            <Text textStyle="body" align="center" mb={2} pl={0.3} pr={0.3}>
              <em>Coming Soon!</em>&nbsp; Boost your brand with Agent Brandi,
              your creative co-pilot to get you from concept to creations and
              campaigns.
            </Text>
          </VStack>
        </CardBody>
      </Card>
    </>
  );
}

function ReportsCarousel({ reports }: { reports: ReportDocument[] }) {
  return (
    <Carousel itemWidth={200} itemSpacing={16} mb={2} w="100%">
      {reports.map((report, idx) => {
        return (
          <Card
            key={idx}
            p={4}
            minHeight="150px"
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
          >
            <Box>
              <Text textStyle="caption" align="left">
                {new Date(report.createdAt).toLocaleDateString()}
              </Text>
              <Text as="header" align="left" flexGrow={1}>
                {getShortSummary(report)}
              </Text>
            </Box>
            <Progress
              isIndeterminate={report.status === ReportStatus.CREATING}
            />
          </Card>
        );
      })}
    </Carousel>
  );
}
