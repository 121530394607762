import React, { FC, useCallback, useState } from 'react';

import {
  Box,
  Button,
  ButtonProps,
  Center,
  Divider,
  Flex,
  Icon,
  Text,
  Progress,
  HStack,
} from '@chakra-ui/react';
import { IoSparklesSharp } from "react-icons/io5";

import { ApiMethods, useApi, useApiPending } from 'src/api';
import { AssetTypes, AssetDocument, useBusinessId, useAssetContext } from 'src/db';
import { AssetCard } from 'src/components';
import { useSelectAsset, useUnselectAsset } from 'src/components/assets/actions';
import { Path, SearchParam, useNavigateWithParams } from 'src/nav';
import { logger, LogSource } from 'src/util/logger';

import { HeaderFooterPage } from './wrappers/HeaderFooterPage';
import { RequireBusinessWithSignup } from './wrappers/RequireBusiness'; 

import {
  UpdateAudienceAssetButton,
  UpdateNameAssetButton,
  UpdateSloganAssetButton,
  UpdateValueAssetButton,
  UpdateBrandStoryAssetButton
} from 'src/components/assets/updateAsset';
import { UpdateTextAssetButtonProps } from 'src/components/assets/updateAsset/UpdateTextAsset';
import { BackButton, NextButton } from 'src/components/NavigationButtons';

export interface AssetProps<T extends AssetDocument> {
  asset: T;
  idx: number;
}

export interface RedirectToProps {
  to: Path;
  modifySearch?: Partial<Record<SearchParam, string|null>>;
}

interface DetailPageProps<T extends AssetDocument> {
  // Title for the page (UI Friendly Name of the Asset)
  title?: string;

  // Human friendly asset name for buttons, e.g. "Select this ${assetUiName}"
  assetUiName?: string
    
  // API Asset Type
  assetType: AssetTypes;

  // Function to render a specific selected asset
  SelectedAsset: FC<AssetProps<T>>;

  // Function to render a specific suggested asset
  SuggestedAsset: FC<AssetProps<T>>;

  // Number of assets to generate from "Suggest More" button
  // Default is 3
  numberToGenerate?: number;

  // Maximum number of assets selectable.
  // Default is 1
  selectionLimit?: number;

  // Message to show if user cannot select anymore.
  selectionLimitErrorMessage?: string;

  // A Button to open an "Add your own" modal. (optional)
  AddAssetButton?: FC<ButtonProps>;

  // Page to redirect to, when max selection is reached (optional)
  redirectTo?: RedirectToProps;

  // Page to redirect when back button is pressed. Button not shown if not provided (optional)
  redirectBack?: RedirectToProps;
}

export function DetailPage<T extends AssetDocument>({
  title,
  ...contentProps
}: DetailPageProps<T>) {
  return (
    <HeaderFooterPage
      pageTitle={title}
      backTo={Path.brandkit}
    >
      <RequireBusinessWithSignup>
        <DetailPageContent<T> {...contentProps}/>
      </RequireBusinessWithSignup>
    </HeaderFooterPage>
  );
}

function DetailPageContent<T extends AssetDocument>({
  assetUiName = '',
  assetType,
  SelectedAsset,
  SuggestedAsset,
  numberToGenerate = 3,
  selectionLimit = 1,
  selectionLimitErrorMessage = 'Unable to select more.',
  AddAssetButton,
  redirectTo,
  redirectBack
}: DetailPageProps<T>){
  const [api, surfaceKnownErrors] = useApi();
  const selectAsset = useSelectAsset();
  const unselectAsset = useUnselectAsset();
  const isGeneratingAny = useApiPending(ApiMethods.GENERATE_ASSETS);
  const businessId = useBusinessId()!;
  const navigate = useNavigateWithParams();
  const [isGeneratingAssets, setIsGeneratingAssets] = useState<boolean>(false);
  const [pendingSelections, setPendingSelections] = useState<Set<string>>(new Set<string>());

  const addPendingSelection = useCallback((idToAdd: string) => {
    if (pendingSelections.has(idToAdd)) { return; }
    pendingSelections.add(idToAdd);
    setPendingSelections(new Set(pendingSelections));
  }, [pendingSelections]);

  const removePendingSelection = useCallback((idToRemove: string) => {
    if (!pendingSelections.has(idToRemove)) { return; }
    pendingSelections.delete(idToRemove);
    setPendingSelections(new Set(pendingSelections));
  }, [pendingSelections]);

  const {
    collection: assets,
    isLoading: assetsAreLoading,
    error: collectionError,
    isComplete,
  } = useAssetContext<T>(assetType);

  const selectedAssets = (assets || []).filter((asset: T) => {
    return asset.selected;
  });

  const unselectedAssets = (assets || []).filter((asset: T) => {
    return !asset.selected && asset.userReview !== 'NEGATIVE';
  });

  const canSelectOrReplace = selectedAssets.length <= selectionLimit || selectionLimit === 1;
  const isAtSelectionLimit = selectedAssets.length >= selectionLimit;

  const generateAssets = useCallback(() => {
    if (isGeneratingAssets) { return; }
    setIsGeneratingAssets(true);
    api.generateAssets({
      businessId, assetType, numberToGenerate
    }, surfaceKnownErrors).finally(() => {
      setIsGeneratingAssets(false);
    });
  }, [
    api, surfaceKnownErrors, isGeneratingAssets,
    businessId, assetType, numberToGenerate
  ]);

  const onSelection = useCallback((asset: T) => {
    // Select Asset Only
    if (selectedAssets.length < selectionLimit) {
      if (pendingSelections.has(asset.id)) { return; }
      selectAsset(asset, (isPending: boolean) => {
        if (isPending) {
          addPendingSelection(asset.id);
        } else {
          removePendingSelection(asset.id);
        }
      }).then(() => {
        // Redirect to brandkit if max is reached when adding the new item
        const redirect = !!redirectTo && selectedAssets.length + 1 === selectionLimit;
        if (redirect) {
          navigate(redirectTo);
        }
      });

    // Replace Currently Selected Asset.
    } else {
      if (selectedAssets.length > 1) {
        logger.error(LogSource.APP, `Cannot Replace. Selected Assets != 1:'\n${selectedAssets}`);
        return;
      }
      const assetToRemove = selectedAssets[0]!;
      if (pendingSelections.has(asset.id) || pendingSelections.has(assetToRemove.id)) { return; }
      unselectAsset(assetToRemove, (isPending: boolean) => {
        if (isPending) {
          addPendingSelection(asset.id);
          addPendingSelection(assetToRemove.id);
        } else {
          removePendingSelection(assetToRemove.id);
        }
      }).then(() => {
        selectAsset(asset, (isPending: boolean) => {
          if (!isPending) {
            removePendingSelection(asset.id);
          }
        });
      }).then(() => {
        // Redirect to brandkit if we are already on max selections
        const redirect = !!redirectTo && selectedAssets.length === selectionLimit;
        if (redirect) {
          navigate(redirectTo);
        }
      });
    }
  }, [
    selectAsset, unselectAsset, selectedAssets, selectionLimit,
    pendingSelections, addPendingSelection, removePendingSelection,
    navigate, redirectTo
  ]);

  if (collectionError) {
    return <em>Could Not Find Assets</em>;
  } 

  const LargeProgress = () => {
    return <Progress
      size='lg'
      isIndeterminate={true}
      my={4}
      width='100%'
    />
  }
  const UserActions = () => {
    return <Center mb={8}>
      <HStack
        spacing={2}
      >
        {isGeneratingAssets ?
          <LargeProgress /> :
          <>
            {!!redirectBack && <BackButton redirectTo={redirectBack}/>}
            {!!AddAssetButton && !isComplete && <AddAssetButton
              variant='outline'
              colorScheme='primary'
            >+&nbsp;Add Your Own</AddAssetButton>}
            <Button
              colorScheme='secondary'
              variant='fill'
              isDisabled={isGeneratingAny}
              onClick={generateAssets}
            >
              <Icon as={IoSparklesSharp} aria-hidden={true} />&nbsp;<span>Suggest More</span>
            </Button>
            {!!redirectTo && <NextButton redirectTo={redirectTo}/> }
          </>}
      </HStack>
    </Center>;
  }

  const selectUpdateAssetButton = (asset: T): FC<UpdateTextAssetButtonProps> | undefined => {
    if (asset.assetType === AssetTypes.audience) {
      return UpdateAudienceAssetButton;
    } else if (asset.assetType === AssetTypes.name) {
      return UpdateNameAssetButton;
    } else if (asset.assetType === AssetTypes.slogan) {
      return UpdateSloganAssetButton;
    } else if (asset.assetType === AssetTypes.value) {
      return UpdateValueAssetButton;
    } else if (asset.assetType === AssetTypes.brandStory) {
      return UpdateBrandStoryAssetButton;
    }
    return undefined;
  }

  if (assetsAreLoading) {
    return <Center>
      <LargeProgress />
    </Center>
  }

  return (<>
    <UserActions/>
    {selectedAssets.map((asset, idx) => {
      return <AssetCard<T>
        key={asset.id}
        value={asset}
        isInProgress={pendingSelections.has(asset.id)}
        includeSelection={true}
        selectionLabel={`Unselect`}
        includeExplanation={true}
        UpdateTextAssetButton={selectUpdateAssetButton(asset)}
      >
        <SelectedAsset asset={asset} idx={idx}/>
      </AssetCard>
    })}

    <Box mb={2}>
      <Divider borderColor='secondary.light' />
    </Box>
    
    <Flex direction='column' align='center' mb={3}>
      <Text as='h2' display='block' textStyle='titleForSection' mb={1}>
        Suggestions
      </Text>
      {selectionLimit > 1 && isAtSelectionLimit && 
        <Text textStyle='bodySmall' align='center' mb={1}>
          {selectionLimitErrorMessage}
        </Text>
      }
    </Flex>
    {unselectedAssets.map((asset, idx) => {
      return <AssetCard<T>
        key={asset.id}
        value={asset}
        isInProgress={pendingSelections.has(asset.id)}
        includeSelection={canSelectOrReplace}
        includeExplanation={true}
        selectionAction={onSelection}
        selectionLabel={`Select This ${assetUiName}`}
        includeRemove={true}
        UpdateTextAssetButton={selectUpdateAssetButton(asset)}
      >
        <SuggestedAsset asset={asset} idx={idx}/>
      </AssetCard>
    })}
      <UserActions/>
  </>);
}
