import { DocumentSnapshot, FirestoreDataConverter, SnapshotOptions } from 'firebase/firestore';

// Credits Document
export interface BusinessCreditsDocument {
  id: string;
  businessId: string; // foreign
  amount: number;
}

export const businessCreditsConverter: FirestoreDataConverter<BusinessCreditsDocument> = {
  toFirestore (document: BusinessCreditsDocument) {
    return {
      businessId: document.businessId,
      amount: document.amount,
    };
  },

  fromFirestore (snapshot: DocumentSnapshot, options: SnapshotOptions): BusinessCreditsDocument{
    const data = snapshot.data(options)!;
    return {
      id: snapshot.id,
      businessId: data.businessId,
      amount: data.amount,
    };
  },
};
