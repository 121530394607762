import {
  Box,
  Card,
  Text,
  Icon,
  IconButton,
  Avatar,
  useToast,
} from "@chakra-ui/react";
import {
  AssetTypes,
  FirestoreContext,
  LogoAssetDocument,
  useBusinessName,
} from "src/db";
import { HeaderFooterPage } from "../wrappers/HeaderFooterPage";
import { Path } from "src/nav";
import { useCallback, useContext, useEffect, useState } from "react";
import {
  useAssetCollection,
  useBusinesses,
  useBusinessId,
  useMembersCollection,
  useProfileId,
} from "src/db/hooks";
import { MdKeyboardArrowRight } from "react-icons/md";
import { IoIosAdd, IoMdTrash } from "react-icons/io";
import { IoLogOutOutline } from "react-icons/io5";
import AddMemberModal, { ROLES } from "./AddMemberModal";
import EditMemberPermissionsModal from "./EditMemberPermissionsModal";
import RemoveMemberModal from "./RemoveMemberModal";
import AssignNewOwnersModal from "./AssignNewOwnersModal";
import {
  UserInvitationDocument,
  userInvitationsConverter,
} from "src/db/model/userInvitation";
import { collection, getDocs, query, where } from "firebase/firestore";
import dayjs from "dayjs";
import LeaveTeamWarningModal from "./LeaveTeamWarningModal";
import { useApi } from "src/api";

export function MembersPage() {
  return (
    <HeaderFooterPage
      pageTitle="Permissions"
      backTo={Path.settings}
    >
      <MembersPageContent />
    </HeaderFooterPage>
  );
}

type ConfirmationModal = {
  isOpen: boolean;
  leaveTeam: boolean;
  profileId: string | null;
};

const confirmationModalDefault = {
  isOpen: false,
  leaveTeam: false,
  profileId: null,
};

type RevokeInvitationnSubmittingType = {
  isLoading: boolean;
  id: string | null;
};

const revokeInvitationSubmittingDefault: RevokeInvitationnSubmittingType = {
  isLoading: false,
  id: null,
};

function MembersPageContent() {
  const [addMembersModal, setAddMemebersModal] = useState<boolean>(false);
  const [editMemberPermissionsModal, setEditMemberPermissionsModal] =
    useState<boolean>(false);
  const [selectedMember, setSelectedMember] = useState();
  const [confirmationModal, setConfirmationModal] = useState<ConfirmationModal>(
    confirmationModalDefault
  );
  const [assignNewOwnersModal, setAssignNewOwnersModal] =
    useState<boolean>(false);
  const [leaveTeamWarning, setLeaveTeamWarning] = useState<boolean>(false);

  const [pendingInvitations, setPendingInvitations] =
    useState<UserInvitationDocument[]>();

  const [revokeInvitationSubmitting, setRevokeInvitationSubmitting] =
    useState<RevokeInvitationnSubmittingType>(
      revokeInvitationSubmittingDefault
    );

  const businessId = useBusinessId()!;

  const fs = useContext(FirestoreContext);

  const [api, surfaceKnownErrors] = useApi();

  const toast = useToast();

  useEffect(() => {
    fetchPendingInvitations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchPendingInvitations = useCallback(async () => {
    const userInvitationQuery = query(
      collection(fs, "userInvitations").withConverter<UserInvitationDocument>(
        userInvitationsConverter
      ),
      where("businessId", "==", businessId)
    );
    const userInvitationSnapshot = await getDocs(userInvitationQuery);
    const invitations = userInvitationSnapshot.docs
      .map((doc) => ({
        ...doc.data(),
      }))
      .filter((ui) => ui.status === "pending");

    setPendingInvitations(invitations);
  }, [businessId, fs]);

  const members = useMembersCollection();

  const businessName = useBusinessName();

  const profileId = useProfileId();

  const businessRole = useBusinesses()?.find(
    (b) => b.businessId === businessId
  )?.role;

  const selectedLogo = useAssetCollection<LogoAssetDocument>(
    AssetTypes.logo
  ).find((logo) => logo.selected);

  const handleEditMemberPermissionModal = (member: any) => {
    setSelectedMember(member);
    setEditMemberPermissionsModal(true);
  };

  const disableEditActions =
    businessRole !== ROLES.admin && businessRole !== ROLES.owner;

  const handleRemoveMember = (profileId: string) => {
    setEditMemberPermissionsModal(false);
    setConfirmationModal({
      isOpen: true,
      profileId,
      leaveTeam: false,
    });
  };

  const handleLeaveTeam = () => {
    if (members?.length === 1) {
      return setLeaveTeamWarning(true);
    }
    if (
      members?.length > 1 &&
      !members.some(
        (member) =>
          member.role === ROLES.owner && member.profileId !== profileId
      ) &&
      businessRole === ROLES.owner
    ) {
      return setAssignNewOwnersModal(true);
    }
    setConfirmationModal({ isOpen: true, leaveTeam: true, profileId });
  };

  const handleRevokeUserInvitation = (invitation: UserInvitationDocument) => {
    if (revokeInvitationSubmitting.isLoading) {
      return;
    }
    setRevokeInvitationSubmitting({ isLoading: true, id: invitation.id });
    api
      .revokeUserInvitation(
        {
          userInvitationId: invitation.id,
        },
        surfaceKnownErrors
      )
      .then(() => {
        toast({
          description: "Invitation successfully revoked",
          duration: 3000,
        });
        fetchPendingInvitations();
      })
      .finally(() => {
        setRevokeInvitationSubmitting(revokeInvitationSubmittingDefault);
      });
  };

  return (
    <>
      <Box display="flex" flexDirection="column">
        <Card p={4} mb="4">
          <Box display="flex" gap="2">
            <Avatar
              name={businessName || undefined}
              src={selectedLogo?.value.logo.imageInfo.imageUrl}
              w={"24px"}
              h={"24px"}
            />
            <Text color="primary.veryDark" fontWeight="bold">
              {businessName || "Your brand name"}
            </Text>
          </Box>
        </Card>
        <Card p={4}>
          <Box pb="2">
            <Text fontWeight="bold">Add Member</Text>
          </Box>
          <Box px="4">
            <Box>
              <Box display="flex" alignItems="center" gap="2">
                <Box>
                  <IconButton
                    icon={
                      <Icon
                        as={IoIosAdd}
                        color={"primary.veryDark"}
                        boxSize="32px"
                      />
                    }
                    aria-label="Menu"
                    onClick={() => setAddMemebersModal(true)}
                    bg={"gray.lightest"}
                    borderRadius="50%"
                    w={"40px"}
                    h={"40px"}
                    isDisabled={disableEditActions}
                  />
                </Box>
                <Box
                  borderBottomColor="gray.light"
                  borderBottomStyle="solid"
                  borderBottomWidth={0.5}
                  width="100%"
                  py="3"
                >
                  <Text textStyle="body" color={"gray.dark"} fontWeight="bold">
                    Invite to team
                  </Text>
                </Box>
              </Box>
              {members &&
                members.map((member: any) => {
                  const { id, profile, role } = member;
                  return (
                    <Box
                      key={id}
                      display="flex"
                      gap="2"
                      alignItems="center"
                      py="2"
                    >
                      <Box>
                        <Avatar
                          name={profile?.profileInfo?.name}
                          src={profile?.profileInfo?.profileImageInfo}
                          w={"40px"}
                          h={"40px"}
                        />
                      </Box>
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        width="100%"
                        borderBottomColor="gray.light"
                        borderBottomStyle="solid"
                        borderBottomWidth={0.5}
                      >
                        <Box>
                          <Text
                            textStyle="body"
                            color={"gray.dark"}
                            fontWeight="bold"
                            maxW="150px"
                            whiteSpace="nowrap"
                            overflow="hidden"
                            textOverflow="ellipsis"
                          >
                            {profile?.profileInfo?.name ||
                              profile?.profileInfo?.email}
                          </Text>
                        </Box>
                        <Box display="flex" alignItems="center">
                          <Text
                            color={"gray.medium"}
                            textTransform="capitalize"
                          >
                            {role}
                          </Text>
                          <IconButton
                            icon={
                              <Icon
                                as={MdKeyboardArrowRight}
                                color={"gray.medium"}
                                boxSize="32px"
                              />
                            }
                            aria-label="Menu"
                            onClick={() =>
                              handleEditMemberPermissionModal(member)
                            }
                            bg={"transparent"}
                            isDisabled={disableEditActions}
                          />
                        </Box>
                      </Box>
                    </Box>
                  );
                })}
            </Box>
          </Box>
        </Card>
        {Boolean(pendingInvitations?.length) && (
          <Card p={4} mt={4}>
            <Box maxH="150px" overflow="scroll">
              <Text fontWeight="bold">Pending Invitations</Text>
              {pendingInvitations &&
                pendingInvitations.map((pi) => (
                  <Box
                    key={pi.id}
                    py="1"
                    justifyContent="space-between"
                    display="flex"
                    borderBottomWidth={1}
                    borderBottomColor="gray.light"
                  >
                    <Box>
                      {pi.email}
                      <Text textStyle="bodySmall">
                        {dayjs(pi.createdAt).format("MMMM D, h:mm A")}
                      </Text>
                    </Box>
                    <Box>
                      <IconButton
                        icon={
                          <Icon as={IoMdTrash} color={"error"} boxSize="26px" />
                        }
                        aria-label="Revoke User Invitation"
                        onClick={() => handleRevokeUserInvitation(pi)}
                        bg={"transparent"}
                        isLoading={
                          revokeInvitationSubmitting.id === pi.id &&
                          revokeInvitationSubmitting.isLoading
                        }
                      />
                    </Box>
                  </Box>
                ))}
            </Box>
          </Card>
        )}
        <Card my={4}>
          <Box
            display="flex"
            alignItems="center"
            p={4}
            gap="2"
            cursor="pointer"
            onClick={handleLeaveTeam}
          >
            <IconButton
              icon={
                <Icon as={IoLogOutOutline} color={"error"} boxSize="24px" />
              }
              aria-label="Menu"
              borderRadius="50%"
              w={"40px"}
              h={"40px"}
            />
            <Text color="error">Leave Team</Text>
          </Box>
        </Card>
      </Box>
      {addMembersModal && (
        <AddMemberModal
          isOpen={addMembersModal}
          pendingInvitations={pendingInvitations}
          existingMembers={members}
          handleClose={() => setAddMemebersModal(false)}
          fetchPendingInvitations={fetchPendingInvitations}
        />
      )}
      {selectedMember && (
        <EditMemberPermissionsModal
          isOpen={editMemberPermissionsModal}
          selectedMember={selectedMember}
          handleClose={() => setEditMemberPermissionsModal(false)}
          handleRemoveMember={handleRemoveMember}
          businessRole={businessRole}
        />
      )}
      <RemoveMemberModal
        isOpen={confirmationModal.isOpen}
        leaveTeam={confirmationModal.leaveTeam}
        profileId={confirmationModal.profileId}
        handleClose={() => setConfirmationModal(confirmationModalDefault)}
      />
      <AssignNewOwnersModal
        isOpen={assignNewOwnersModal}
        members={members.filter((m) => m.role !== ROLES.owner)}
        handleClose={() => setAssignNewOwnersModal(false)}
        handleLeaveTeam={() =>
          setConfirmationModal({ isOpen: true, leaveTeam: true, profileId })
        }
      />
      <LeaveTeamWarningModal
        isOpen={leaveTeamWarning}
        handleClose={() => setLeaveTeamWarning(false)}
      />
    </>
  );
}
