import React, { useState, useCallback, useEffect } from "react";

import {
  Flex,
  Card,
  CardHeader,
  CardBody,
  Text,
  FormControl,
  Input,
  Button,
  Center,
  Image,
  Icon,
  VStack,
  Box,
} from "@chakra-ui/react";
import { useToast, UseToastOptions } from "@chakra-ui/react";

import { GrFormNextLink } from "react-icons/gr";

import { northStar } from "src/images";
import { useBusinessId } from "src/db";

import { HeaderFooterPage } from "./wrappers/HeaderFooterPage";

import { useApi, ApiResponse } from "src/api";
import {
  useNavigateWithParams,
  Path,
  useSearchParam,
  SearchParam,
} from "src/nav";

import { useCreateBusinessPrefill } from "./createBusiness/hooks";

export function UrlOnboarding() {
  const [urlValue, setUrlValue] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const toast = useToast();
  const navigate = useNavigateWithParams();
  const [prefill, setPrefill] = useCreateBusinessPrefill();
  const [api, surfaceKnownErrors] = useApi();
  const businessId = useBusinessId();
  const joinBusinessId = useSearchParam(SearchParam.joinBusinessId);

  useEffect(() => {
    if ((!!prefill && !!prefill.description) || joinBusinessId) {
      console.log(
        "Navigating to create business page with prefill: \n",
        prefill
      );
      navigate({ to: Path.createBusiness });
    }
  }, [prefill, navigate, businessId, joinBusinessId]);

  const errorProps: UseToastOptions = {
    status: "error",
    isClosable: true,
    duration: null,
  };

  const handleSkipButton = () => {
    navigate({ to: Path.home, modifySearch: { newBusiness: undefined } });
  };

  const surfaceKnownErrorsAndBlock = useCallback(
    (e: ApiResponse<any>) => {
      if (e.errorType === "URL_SCRAPER_ERROR") {
        navigate({ to: Path.home });
      } else {
        surfaceKnownErrors(e);
      }
    },
    [surfaceKnownErrors, navigate]
  );

  const getURLThatExists = async (url: string): Promise<string | null> => {
    const isValidUrl = (urlString: string) => {
      var urlPattern = new RegExp(
        "^(https?:\\/\\/)?" + // validate protocol
          "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // validate domain name
          "((\\d{1,3}\\.){3}\\d{1,3}))" + // validate OR ip (v4) address
          "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // validate port and path
          "(\\?[;&a-z\\d%_.~+=-]*)?" + // validate query string
          "(\\#[-a-z\\d_]*)?$",
        "i"
      ); // validate fragment locator
      return !!urlPattern.test(urlString);
    };
    if (isValidUrl(url)) {
      return url;
    }
    return null;
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const formattedUrl = urlValue.trim();
    if (formattedUrl === "") {
      return;
    }

    if (!isValidUrl(formattedUrl)) {
      toast({
        ...errorProps,
        description: "The provided URL is invalid. Please try again.",
      });
      return;
    }

    const urlThatExists = await getURLThatExists(formattedUrl);
    if (urlThatExists === null) {
      toast({
        ...errorProps,
        description: "The provided URL does not exist. Please try again.",
      });
      return;
    }
    setIsSubmitting(true);
    try {
      const response = await api.scrapeBusinessUrl(
        {
          url: formattedUrl,
        },
        surfaceKnownErrorsAndBlock
      );
      if (response.status !== "success") {
        return;
      }
      const {
        data: { businessDescription, businessName, businessLogoUrl },
      } = response;
      if (businessDescription) {
        setPrefill((previous) => {
          return {
            ...previous,
            description: businessDescription,
            name: businessName,
            logoUrl: businessLogoUrl,
          };
        });
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  const isValidUrl = (url: string): boolean => {
    // Regular expression to match valid URLs
    const regex =
      /^((http|https):\/\/)?([a-zA-Z0-9_-]+\.)*[a-zA-Z0-9_-]+\.[a-z]{2,6}(\/[a-zA-Z0-9-._~%+&=]*)*$/;

    return regex.test(url);
  };

  return (
    <Flex
      as="section"
      direction="column"
      justify="flex-start"
      boxShadow="section"
    >
      <HeaderFooterPage pageTitle="Tell us about your business">
        <Box px={8} py={4}>
          <Center mb={8}>
            <Image
              src={northStar}
              role="presentation"
              width="60px"
              objectFit="contain"
            />
          </Center>
          <Card p={3} mb={4}>
            <CardHeader mb={2}>
              <Text as="header" textStyle="titleForSection" align="center">
                Provide your business website
              </Text>
            </CardHeader>
            <CardBody>
              {/* <Text textStyle="body2">
                We will try to learn your brand from your website.
              </Text> */}
              <VStack align="center">
                <Text textStyle="body" align="center" mb={2}>
                  We'll learn about your business from there. <br /> Skip if you
                  do not have one.
                </Text>
              </VStack>
              <FormControl flex="1 0 fit-content">
                <form onSubmit={handleSubmit}>
                  <Input
                    type="text"
                    placeholder="For example: https://www.example.com"
                    variant="filled"
                    mt={2}
                    onChange={(e) => {
                      setUrlValue(e.target.value);
                    }}
                  />
                  <Flex justify="space-between">
                    <Button
                      type="button"
                      variant="fill"
                      colorScheme="secondary"
                      mt={4}
                      size="md"
                      flex="0 0 fit-content"
                      onClick={handleSkipButton}
                    >
                      Skip
                    </Button>
                    <Button
                      type="submit"
                      variant="fill"
                      colorScheme="primary"
                      mt={4}
                      size="md"
                      flex="0 0 fit-content"
                      isDisabled={!urlValue}
                      isLoading={isSubmitting}
                      rightIcon={<Icon as={GrFormNextLink} />}
                      autoFocus={true}
                    >
                      Next
                    </Button>
                  </Flex>
                </form>
              </FormControl>
            </CardBody>
          </Card>
        </Box>
      </HeaderFooterPage>
    </Flex>
  );
}
