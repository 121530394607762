import {
  DocumentSnapshot,
  FirestoreDataConverter,
  SnapshotOptions,
} from "firebase/firestore";

export enum BusinessTier {
  FREE = "free",
  PAY_AS_YOU_GO = "pay_as_you_go",
  SUBSCRIPTION = "subscription",
}

export interface BusinessDocument {
  id: string;
  description: string;
  isBrandKitLocked: boolean;
  businessDifferentiator?: string;
  tier: BusinessTier;
  brandKitZipInfo?: {
    storageId: string;
    zipUrl: string;
  };
  isClaimed: boolean;
}

export const businessConverter: FirestoreDataConverter<BusinessDocument> = {
  toFirestore(document: BusinessDocument) {
    return {
      businessDescription: document.description,
      isBrandKitLocked: document.isBrandKitLocked,
      brandKitZipInfo: document.brandKitZipInfo,
      isClaimed: document.isClaimed,
      businessDifferentiator: document.businessDifferentiator,
      tier: document.tier,
    };
  },

  fromFirestore(
    snapshot: DocumentSnapshot,
    options: SnapshotOptions
  ): BusinessDocument {
    const data = snapshot.data(options)!;
    return {
      id: snapshot.id,
      description: data.businessDescription,
      isBrandKitLocked: data.isBrandKitLocked,
      brandKitZipInfo: data.brandKitZipInfo,
      isClaimed: data.isClaimed,
      businessDifferentiator: data.businessDifferentiator,
      tier: data.tier as BusinessTier,
    };
  },
};
