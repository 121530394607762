import React, { SyntheticEvent, useCallback, useEffect, useRef } from "react";

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Image,
  Text,
  VStack,
  Progress,
  Modal,
  ModalOverlay,
  Textarea,
  IconButton,
  Box,
} from "@chakra-ui/react";

import { Carousel } from "src/components";
import { ConversationModalContent, ModalAction } from "src/components/chat";

import {
  ActionTypes,
  ShareSocialMediaPostActionDocument,
  useActionCollection,
  useSelectedDate,
  useBusinessId,
} from "src/db";
import { SocialMediaPostPreview } from "src/components/actions";
import { avatar } from "src/images";
import { Path, useNavigateWithParams } from "src/nav";

import { CreateBusinessConversation } from "./createBusiness/CreateBusinessConversation";
import { HeaderFooterPage } from "./wrappers/HeaderFooterPage";
import { RequireBusinessOnly } from "./wrappers/RequireBusiness";
import {
  useActiveThreadId,
  useExternalConversation,
} from "src/components/chat/hooks";

export function HomePage() {
  const [, setSelectedDate] = useSelectedDate();

  useEffect(() => {
    setSelectedDate(new Date());
  }, [setSelectedDate]);

  return (
    <HeaderFooterPage pageTitle="Brand Hub">
      <RequireBusinessOnly OrElse={<CreateBusiness />}>
        <HomePageContent />
      </RequireBusinessOnly>
    </HeaderFooterPage>
  );
}

function HomePageContent() {
  const navigate = useNavigateWithParams();
  const posts = useActionCollection<ShareSocialMediaPostActionDocument>(
    ActionTypes.shareSocialMediaPostPreview
  );

  const goToBrandActions = useCallback(
    (e: SyntheticEvent) => {
      e.stopPropagation();
      navigate({ to: Path.brandactions });
    },
    [navigate]
  );

  return (
    <Box display="flex" flexDirection="column">
      <Card mb={4} p={2}>
        <BrandiSearch />
      </Card>
      <Card mb={12} p={6} onClick={goToBrandActions}>
        <CardHeader mb={2}>
          <Text as="header" textStyle="titleForSection" align="center" mb={2}>
            Brand Actions
          </Text>
        </CardHeader>
        <CardBody mb={2}>
          <VStack align="center">
            <Text textStyle="body" align="center" mb={2} pl={0.3} pr={0.3}>
              Tailored-to-you actions to grow your brand weekly
            </Text>
            {posts.length === 0 && (
              <Progress isIndeterminate={true} width="50%" my={4} mx="auto" />
            )}
            <PostsCarousel posts={posts} />
          </VStack>
        </CardBody>
        <CardFooter></CardFooter>
        <VStack align="center">
          <Button
            colorScheme="primary"
            variant="fill"
            minWidth="200px"
            mx="auto"
            onClick={goToBrandActions}
          >
            See Actions
          </Button>
        </VStack>
      </Card>
    </Box>
  );
}

function BrandiSearch() {
  const businessId = useBusinessId();
  const threadId = useActiveThreadId();
  if (!businessId || !threadId) {
    // Not an error, just means the user is not signed in.
    return <></>;
  } else {
    return <BrandiSearchButton businessId={businessId} threadId={threadId} />;
  }
}
interface ChatButtonProps {
  businessId: string;
  threadId: string;
}

function BrandiSearchButton({ businessId, threadId }: ChatButtonProps) {
  const fabRef = useRef<HTMLButtonElement>(null);

  const conversation = useExternalConversation(businessId, threadId);
  return (
    <>
      <Textarea
        bg="grayscale.light"
        borderRadius="md"
        focusBorderColor="secondary.light"
        placeholder="Ask Agent Brandi..."
        resize="none"
        rows={1}
        onClick={() => conversation.toggleModal(true)}
      />
      <IconButton
        type="submit"
        variant="fill"
        position="absolute"
        bottom="12px"
        right="12px"
        boxSize="32px"
        icon={<Image src={avatar} alt="Talawa" boxSize="64px" />}
        aria-label="Send"
        isRound={true}
        onClick={() => conversation.toggleModal(true)}
      ></IconButton>
      <Modal
        onClose={() => {
          conversation.toggleModal(false);
        }}
        finalFocusRef={fabRef}
        isOpen={conversation.isModalOpen}
        scrollBehavior="inside"
      >
        <ModalOverlay />
        <ConversationModalContent />
      </Modal>
    </>
  );
}

function CreateBusiness() {
  const getStarted = useRef<HTMLButtonElement>(null);
  const toggleModal = useRef<ModalAction>(null);

  const openConversation = () => {
    !!toggleModal.current && toggleModal.current(true);
  };

  return (
    <VStack align="center">
      <Text textStyle="direction" align="center" mb={2}>
        Tell Us About Your Business
      </Text>
      <CreateBusinessConversation
        finalFocusRef={getStarted}
        toggleModalRef={toggleModal}
        flex="1"
        mx={2}
      />
      <Button
        variant="fill"
        ref={getStarted}
        onClick={openConversation}
        width="100%"
        mb={4}
      >
        Get Started
      </Button>
    </VStack>
  );
}

function PostsCarousel({
  posts,
}: {
  posts: ShareSocialMediaPostActionDocument[];
}) {
  return (
    <Carousel itemWidth={200} itemSpacing={16} mb={2}>
      {posts.map((post, idx) => {
        return (
          <Card key={idx} p={4}>
            <Text as="h4" textStyle="caption" align="left">
              Share
            </Text>
            <SocialMediaPostPreview post={post} />
          </Card>
        );
      })}
    </Carousel>
  );
}
