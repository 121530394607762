// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

// ------------------ DEV ------------------
// export const firebaseConfig = {
//   apiKey: "AIzaSyCAM8NA1NOAsaLnkghHK43nMydmo4gK9dA",
//   authDomain: "talawa-dev.web.app", // NOTE: User .web.app instead of .firebaseapp.com
//   projectId: "talawa-dev",
//   storageBucket: "talawa-dev.appspot.com",
//   messagingSenderId: "651903022604",
//   appId: "1:651903022604:web:538c986c41806833ce6029",
//   measurementId: "G-BGWLVGNBSW",
// };
// ------------------ PROD ------------------
export const firebaseConfig = {
  apiKey: "AIzaSyB49QfleHE3NIim0jOV9cTAIUiUKwP63ak",
  authDomain: "app.talawa.ai",
  projectId: "talawa-prod",
  storageBucket: "talawa-prod.appspot.com",
  messagingSenderId: "828398616319",
  appId: "1:828398616319:web:d7c730331fffd212d0d6ca",
  measurementId: "G-SJG27JECLB",
};

export const backendConfig = {
  // domainUrl: "http://127.0.0.1:5000",
  // domainUrl: "https://talawa-dev.uc.r.appspot.com",
  domainUrl: "https://talawa-prod.uc.r.appspot.com",
  loggingToken: "rU5IjBt8Ek6Cx2k"
};

export const stripeConfig = {
  // Test Environment
  // customerPortalBaseLink:
  //   "https://billing.stripe.com/p/login/test_7sI6qGewzbDU5nq6oo",
  // Prod Environment
  customerPortalBaseLink:
    "https://billing.stripe.com/p/login/eVaaIL4ky0Lf15mdQQ",
};