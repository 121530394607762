import React from "react";
import { useEffect, useContext } from "react";
import "./App.css";

import { ChakraBaseProvider } from "@chakra-ui/react";
import extendedTheme from "./theme/chakraTheme";

import { Routes, Route, useLocation } from "react-router-dom";

import {
  // Auth / Settings Pages
  SettingsPage,
  CreditsPage,
  AccountPage,
  LoginPage,
  LogoutPage,
  HandleActionPage,
  MembersPage,

  // Public Visible Pages
  PricingPage,

  // Guest Visible Pages
  HomePage,

  // Top Level Products
  BrandKitPage,
  BrandActionsPage,
  BrandPage,
  CreatePage,

  // Brand Kit Detail Pages
  AudiencePage,
  ColorsPage,
  FontPage,
  LogoPage,
  NamePage,
  SloganPage,
  BrandStoryPage,
  ValuePage,

  // Alternative Onboarding
  UpdateBrandStoryPage,
  UrlOnboarding,

  // Catch All
  ErrorPage,
  ErrorPageCode,
  CreateBusinessPage,
} from "./pages";
import { Path } from "src/nav";
import { AnalyticsContext } from "./analytics";
import { logEvent } from "firebase/analytics";
import { PayWall, PayWallProvider } from "src/components/paywall";
import { ConversationProvider } from "./chat/ConversationProvider";
import { BrandReportsPage } from "./pages/BrandReportsPage";
import { UpdateBusinessDifferentiatorPage } from "./pages/UpdateBusinessDifferentiator";
import { AssetsPage } from "./pages/AssetsPage";

function App() {
  const analyticsContext = useContext(AnalyticsContext);

  const location = useLocation();
  useEffect(() => {
    if (analyticsContext) {
      logEvent(analyticsContext, "page_view");
    }
  }, [location, analyticsContext]);

  return (
    <PayWallProvider>
      <ConversationProvider>
        <ChakraBaseProvider theme={extendedTheme}>
          {/* Note: <BrowserRouter> is in outside of App, in index.tsx */}
          <Routes>
            {/** Critical User Journey */}
            <Route path={Path.landing} element={<LoginPage />} />
            <Route path={Path.home} element={<HomePage />} />

            {/** Admin */}
            <Route path={Path.settings} element={<SettingsPage />} />
            <Route path={Path.account} element={<AccountPage />} />
            <Route path={Path.credits} element={<CreditsPage />} />
            <Route path={Path.pricing} element={<PricingPage />} />
            <Route path={Path.members} element={<MembersPage />} />

            {/** Auth */}
            <Route path={Path.signup} element={<LoginPage isSignup={true} />} />
            <Route path={Path.login} element={<LoginPage />} />
            <Route path={Path.logout} element={<LogoutPage />} />
            <Route path={Path.handleAction} element={<HandleActionPage />} />

            {/** Brand */}
            <Route path={Path.brand} element={<BrandPage />} />

            {/** Brand */}
            <Route path={Path.create} element={<CreatePage />} />

            {/** Create Business */}
            <Route path={Path.createBusiness} element={<CreateBusinessPage />} />

            {/** Brand Kit */}
            <Route path={Path.brandkit} element={<BrandKitPage />} />
            <Route path={Path.audience} element={<AudiencePage />} />
            <Route path={Path.color} element={<ColorsPage />} />
            <Route path={Path.font} element={<FontPage />} />
            <Route path={Path.logo} element={<LogoPage />} />
            <Route path={Path.assets} element={<AssetsPage />} />
            <Route path={Path.name} element={<NamePage />} />
            <Route path={Path.slogan} element={<SloganPage />} />
            <Route path={Path.brandStory} element={<BrandStoryPage />} />
            <Route path={Path.value} element={<ValuePage />} />

            {/** Brand Actions */}
            <Route path={Path.brandactions} element={<BrandActionsPage />} />

            {/** Brand Reports */}
            <Route path={Path.brandreports} element={<BrandReportsPage />} />

            {/* Alternative Onboarding */}
            <Route
              path={Path.updateBrandStory}
              element={<UpdateBrandStoryPage />}
            />
            <Route
              path={Path.updateBusinessDifferentiator}
              element={<UpdateBusinessDifferentiatorPage />}
            />
            <Route path={Path.urlOnboarding} element={<UrlOnboarding />} />

            {/** Error Handling */}
            <Route path={Path.error} element={<ErrorPage />} />
            <Route
              path="*"
              element={<ErrorPage errorCode={ErrorPageCode.NOT_FOUND} />}
            />
          </Routes>
          <PayWall />
        </ChakraBaseProvider>
      </ConversationProvider>
    </PayWallProvider>
  );
}

export default App;
